import { DateTime } from 'luxon';
import { Pricing } from 'pages/PrivateOffers/pages/Next/aws/api/types/AwsPrivateOffer';
import { getUtcDateTimeFromSerializedIsoDateTime } from '../date/dateUtils';

export const getUsageDuration = (
  pricing: Pricing,
  acceptedAtDate,
): number | null => {
  if (!pricing.serviceEndAt) {
    return null;
  }
  const serviceEndDateTime = getUtcDateTimeFromSerializedIsoDateTime(
    pricing.serviceEndAt,
  );
  const acceptedAtDateTime = getUtcDateTimeFromSerializedIsoDateTime(
    acceptedAtDate || DateTime.utc(),
  );
  return Math.ceil(
    Math.abs(
      acceptedAtDateTime.diff(serviceEndDateTime, ['days']).toObject()?.days,
    ),
  );
};
