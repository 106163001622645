import { z } from 'zod';

export const PhoneSchema = z
  .string()
  .min(1, 'Required')
  .min(10, 'Phone number must have at least 10 digits')
  .max(15, 'Phone number too long')
  .refine((val) => {
    // Remove all non-digit characters
    const digits = val.replace(/\D/g, '');
    return digits.length >= 10 && digits.length <= 15;
  }, 'Invalid phone number format');
