import { Agreement } from 'pages/PrivateOffers/pages/Next/aws/api/types/Agreement';
import { AgreementJSON } from 'pages/PrivateOffers/pages/Next/aws/api/types/AgreementsResponseJSON';

export const agreementJSONToAgreements = (
  agreements: AgreementJSON[],
): Agreement[] =>
  agreements.map((a) => ({
    id: a.id,
    status: a.status,
    productId: a.productid,
    publicProductId: a.public_productid,
    productType: a.product_type,
    offerId: a.offerid,
    buyerBillingAccountRef: a.buyer_billing_account_ref,
    startDate: a.start_date,
    endDate: a.end_date,
  }));
