import { useCallback } from 'react';
import { Banner } from '@tackle-io/platform-ui';
import { OfficeBuilding } from 'mdi-material-ui';
import { CoSellMsftCreateFormDataId, FormSectionConfig } from '../constants';
import FormSectionCard from '../components/FormSectionCard';
import useToggleState from 'hooks/useToggleState';
import { AddCustomerFormDrawer } from './AddCustomerForm';
import Subsection from '../components/Subsection';
import ControlledTextField from '../controlledFields/ControlledTextField';
import InfoItem from 'packages/cosell/src/components/InfoItem/InfoItem';
import { Box, Grid, makeStyles } from 'vendor/material';
import { useReferralFormContext } from '../ReferralFormContext';
import {
  customerTeamMemberFields,
  selectedCustomerAccountHiddenFields,
  type SelectedCustomerAccountType,
} from './sectionSchema';

const useStyle = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  detailsGroup: {
    '& > *': {
      borderBottom: '1px solid #EBECF0', // neutral-20
    },
  },
}));

const NoCustomerSelectedView = ({
  onAddCustomer,
}: {
  onAddCustomer: () => void;
}) => {
  return (
    <>
      <Banner title="Customer is required to create a referral." type="info" />
      <FormSectionCard.BlockMessage>
        You haven't added a customer yet.
      </FormSectionCard.BlockMessage>
      <FormSectionCard.AddButton onClick={onAddCustomer}>
        Add customer
      </FormSectionCard.AddButton>
    </>
  );
};

const MaccEligibilityBanner = ({ isEligible }: { isEligible: boolean }) => {
  const config = {
    eligible: {
      title: 'This customer is MACC eligible.',
      body: null,
      type: 'info',
    },
    notEligible: {
      title: 'This customer is not MACC eligible.',
      body: 'MACC ineligible customers are not qualified to participate in Microsoft’s Azure Consumption Commitment (MACC) program. They do not have a committed spend with Azure, and may not be a good fit for marketplace transactions.',
      type: 'warning',
    },
  } as const;

  const content = isEligible ? config.eligible : config.notEligible;

  return (
    <Banner body={content.body} title={content.title} type={content.type} />
  );
};

const CustomerContactFormSection = () => {
  const config = {
    title: 'Customer contact',
    helpText:
      'Adding a valid email and phone for the customer contact helps Microsoft sellers evaluate the opportunity better and increases the acceptance rate.',
  };
  return (
    <Subsection title={config.title} helpText={config.helpText}>
      {customerTeamMemberFields.map((field) => (
        <ControlledTextField
          dataId={`${CoSellMsftCreateFormDataId.FIELD_PREFIX}-${field.name}`}
          key={field.name}
          name={field.name}
          label={field.label}
          required={field.required}
          type={field.type}
        />
      ))}
    </Subsection>
  );
};

const CustomerSelectedView = ({
  onSearchAgain,
}: {
  onSearchAgain: () => void;
}) => {
  const formContext = useReferralFormContext();
  const values = formContext.getValues().customerProfile;
  const classes = useStyle();

  return (
    <div data-testid="customer-selected-view" className={classes.content}>
      <MaccEligibilityBanner isEligible={values.isMaccEligible === 'Yes'} />
      <Box className={classes.detailsGroup}>
        <Box>
          <InfoItem
            itemTitle="Company name"
            value={values.name}
            dataId={'msft-customer-profile-name'}
          />
        </Box>
        <Box>
          <InfoItem
            itemTitle="Location"
            value={`${values.address.addressLine1}, ${values.address.city}, ${values.address.country}`}
          />
        </Box>
        <Grid item container>
          <InfoItem
            itemTitle="Microsoft account ID"
            value={values.ids?.[0]?.id}
          />
          <InfoItem
            itemTitle="MACC eligibility"
            value={values.isMaccEligible}
          />
        </Grid>
      </Box>

      <FormSectionCard.AddButton onClick={onSearchAgain}>
        Search again
      </FormSectionCard.AddButton>

      <CustomerContactFormSection />
    </div>
  );
};

export const CustomerProfileFormSection = () => {
  const [drawerIsOpen, toggleDrawer] = useToggleState(false);
  const formContext = useReferralFormContext();
  const { getValues, setValue } = formContext;
  const formValues = getValues();
  const hasSelectedCustomer = !!formValues.customerProfile?.ids?.[0]?.id;

  const handleSelectCustomer = useCallback(
    (data: SelectedCustomerAccountType) => {
      setValue('customerProfile', data, {
        shouldValidate: true,
        shouldDirty: true,
      });
      toggleDrawer();
    },
    [setValue, toggleDrawer],
  );

  return (
    <>
      <FormSectionCard
        title={FormSectionConfig.customerProfile.title}
        icon={
          <OfficeBuilding
            data-testid="office-building-icon"
            style={{ fill: '#253858' }}
          />
        }
        data-id={FormSectionConfig.customerProfile.dataId}
        sectionId={FormSectionConfig.customerProfile.id}
      >
        <div style={{ display: 'none' }}>
          {selectedCustomerAccountHiddenFields.map((fieldName) => (
            <input
              key={fieldName}
              type="hidden"
              ref={formContext.register}
              name={fieldName}
            />
          ))}
        </div>

        {!hasSelectedCustomer ? (
          <NoCustomerSelectedView onAddCustomer={toggleDrawer} />
        ) : (
          <CustomerSelectedView onSearchAgain={toggleDrawer} />
        )}
      </FormSectionCard>
      <AddCustomerFormDrawer
        data-testid="add-customer-form-drawer"
        open={drawerIsOpen}
        onClose={toggleDrawer}
        onSelectItem={handleSelectCustomer}
      />
    </>
  );
};

export default CustomerProfileFormSection;
