import { createContext, Dispatch, SetStateAction } from 'react';
import useAwsPrivateOfferApi from 'pages/PrivateOffers/pages/Next/aws/api/useAwsPrivateOfferApi';
import useMsftPrivateOfferApi from 'pages/PrivateOffers/pages/Next/msft/api/useMsftPrivateOfferApi';
import { Offer } from 'pages/PrivateOffers/pages/Next/generic/types/Offer';
import useMarketplaceAgnosticOfferApi from 'pages/PrivateOffers/pages/Next/generic/hooks/useMarketplaceAgnosticOfferApi';

export interface SchemaValidationError {
  message: string;
  schema_validation_errors: string[];
}

export interface ImportValidationError {
  message: string;
}

export interface LogicalValidationError {
  code: string;
  message: string;
}

export interface InvalidCreateOfferPayloadError {
  code: string;
  description: string;
  error_codes: string[];
}

export interface MarketplaceInvariantError {
  message: string;
}

export type OfferSubmissionError =
  | SchemaValidationError
  | LogicalValidationError[]
  | InvalidCreateOfferPayloadError
  | MarketplaceInvariantError
  | ImportValidationError
  | null;

export interface OfferSubmissionErrors {
  [poId: string]: OfferSubmissionError;
}

export const NewOfferOfferSubmissionErrorsKey = 'new';

export interface OfferApiFunctions<O extends Offer> {
  getOffer: (poId: string) => Promise<O | null>;
  getOfferSilently: (poId: string) => Promise<O | null>;
  updateOffer: (
    poId: string,
    updatedOffer: Partial<O>,
    createInMarketplace: boolean,
  ) => Promise<O | null>;
  cancelOffer: (poId: string) => Promise<void>;
  sendBuyerInstructions: (poId: string) => Promise<void>;
  archiveOffer: (poId: string) => Promise<void>;
}

export type ContextValueSetters = {
  setOfferSubmissionErrors: Dispatch<SetStateAction<OfferSubmissionErrors>>;
  setSubmitting: Dispatch<SetStateAction<{ [k: string]: boolean }>>;
  setLoading: Dispatch<SetStateAction<{ [k: string]: boolean }>>;
  setSynced: Dispatch<SetStateAction<{ [k: string]: boolean }>>;
  setOfferImportError: Dispatch<SetStateAction<ImportValidationError>>;
};

export interface ApiContextValues {
  getOfferSubmissionError: (poId: string | null) => OfferSubmissionError | null;
  dismissOfferSubmissionError: (poId: string | null) => void;
  isSubmitting: (k: string) => boolean;
  isLoading: (k: string) => boolean;
  hasSynced: (k: string) => boolean;
  apiForMarketplace: OfferApiFunctions<Offer> | null;
  marketplaceAgnosticApi: ReturnType<
    typeof useMarketplaceAgnosticOfferApi
  > | null;
  awsApi: ReturnType<typeof useAwsPrivateOfferApi>;
  msftApi: ReturnType<typeof useMsftPrivateOfferApi>;
  offerImportError: ImportValidationError | null;
  setOfferImportError: Dispatch<SetStateAction<ImportValidationError>>;
}

const ApiContext = createContext<ApiContextValues>({
  getOfferSubmissionError: (_: string | null) => null,
  dismissOfferSubmissionError: (_: string | null) => {},
  isSubmitting: (_: string) => false,
  isLoading: (_: string) => false,
  hasSynced: (_: string) => false,
  apiForMarketplace: null,
  marketplaceAgnosticApi: null,
  awsApi: null,
  msftApi: null,
  offerImportError: null,
  setOfferImportError: () => {},
});

export default ApiContext;
