import { type FC } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { ReferralFormFields } from './referralFormSchema';
import { ReferralFormSchema } from './referralFormSchema';

/**
 * provides the react-hook-form context with
 * the ReferralFormFields type preset
 */
export const useReferralFormContext = () => {
  const formMethods = useFormContext<ReferralFormFields>();
  return formMethods;
};

const defaultValues = {
  customerProfile: {
    name: '',
    address: {
      addressLine1: '',
      city: '',
      country: '',
    },
    ids: [{ id: '', profileType: 'External' as const }],
    isMatchingComplete: 'false' as const,
    isMaccEligible: 'Yes' as const,
    team: [
      {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      },
    ],
  },
};

export const ReferralFormProvider: FC<{
  initialValues?: Partial<ReferralFormFields>;
}> = ({ children, initialValues }) => {
  const formMethods = useForm<ReferralFormFields>({
    defaultValues: initialValues ?? defaultValues,
    mode: 'onChange',
    resolver: zodResolver(ReferralFormSchema),
  });

  return <FormProvider {...formMethods}>{children}</FormProvider>;
};
