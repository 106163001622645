import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getLegacyAuthToken } from 'pages/PrivateOffers/pages/Next/generic/api/offerAPIs';
import ApiContext, {
  ApiContextValues,
  ImportValidationError,
  NewOfferOfferSubmissionErrorsKey,
  OfferSubmissionErrors,
} from './apiContext';
import { OfferAPIKey } from 'pages/PrivateOffers/pages/Next/generic/ApiContext/offerAPIKey';
import useAwsPrivateOfferApi from 'pages/PrivateOffers/pages/Next/aws/api/useAwsPrivateOfferApi';
import useMsftPrivateOfferApi from 'pages/PrivateOffers/pages/Next/msft/api/useMsftPrivateOfferApi';
import { Marketplace } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';
import useMarketplaceAgnosticOfferApi from 'pages/PrivateOffers/pages/Next/generic/hooks/useMarketplaceAgnosticOfferApi';
import { isCanvasSession } from 'packages/salesforce-canvas/api/salesforceSessionStorage';
import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';

const initialState: { [k: string]: boolean } = {
  [OfferAPIKey.Silent]: false,
  [OfferAPIKey.User]: false,
  [OfferAPIKey.Users]: false,
  [OfferAPIKey.Products]: false,
  [OfferAPIKey.Offer]: false,
  [OfferAPIKey.OffersForOpportunity]: false,
  [OfferAPIKey.AgreementOffer]: false,
  [OfferAPIKey.VendorCurrencies]: false,
  [OfferAPIKey.MarketplacePricing]: false,
  [OfferAPIKey.Document]: false,
  [OfferAPIKey.SalesforceDataMapping]: false,
  [OfferAPIKey.ActiveAgreements]: false,
};

export interface ApiContextProviderProps {
  marketplace?: Marketplace;
  children: React.ReactNode;
}

export const ApiContextProvider = ({
  marketplace,
  children,
}: ApiContextProviderProps) => {
  const { getOrRenewAccessToken } = useCanvasSession();

  const [offerSubmissionErrors, setOfferSubmissionErrors] =
    useState<OfferSubmissionErrors>({});
  const [offerImportError, setOfferImportError] =
    useState<ImportValidationError | null>(null);

  const getOfferSubmissionError = useCallback(
    (poId: string | null) => {
      const key = poId || NewOfferOfferSubmissionErrorsKey;

      return offerSubmissionErrors[key];
    },
    [offerSubmissionErrors],
  );

  const dismissOfferSubmissionError = useCallback((poId: string | null) => {
    const key = poId || NewOfferOfferSubmissionErrorsKey;

    setOfferSubmissionErrors((previousOfferSubmissionErrors) => ({
      ...previousOfferSubmissionErrors,
      [key]: null,
    }));
  }, []);

  const [submitting, setSubmitting] = useState<{ [k: string]: boolean }>({
    [OfferAPIKey.Offer]: false,
    [OfferAPIKey.Document]: false,
  });

  const [synced, setSynced] = useState<{ [k: string]: boolean }>(initialState);

  const [loading, setLoading] =
    useState<{ [k: string]: boolean }>(initialState);

  const isSubmitting = useCallback(
    (k: OfferAPIKey) => submitting[k],
    [submitting],
  );

  const isLoading = useCallback((k: OfferAPIKey) => loading[k], [loading]);
  const hasSynced = useCallback((k: OfferAPIKey) => synced[k], [synced]);

  useEffect(() => {
    return () => {
      setOfferSubmissionErrors({});
      setSubmitting({});
      setSynced({});
      setLoading({});
    };
  }, []);

  const apiTokenProvider = isCanvasSession()
    ? getOrRenewAccessToken
    : getLegacyAuthToken;

  const contextValueSetters = {
    setOfferSubmissionErrors,
    setSubmitting,
    setLoading,
    setSynced,
    setOfferImportError,
  };

  const marketplaceAgnosticApi = useMarketplaceAgnosticOfferApi(
    apiTokenProvider,
    contextValueSetters,
  );

  const awsApi = useAwsPrivateOfferApi(apiTokenProvider, contextValueSetters);
  const msftApi = useMsftPrivateOfferApi(apiTokenProvider, contextValueSetters);

  const apiForMarketplace = useMemo(
    () =>
      marketplace === Marketplace.Aws
        ? awsApi
        : marketplace === Marketplace.Azure
        ? msftApi
        : null,
    [marketplace, awsApi, msftApi],
  );

  const contextValues: ApiContextValues = {
    getOfferSubmissionError,
    dismissOfferSubmissionError,
    isSubmitting,
    isLoading,
    hasSynced,
    apiForMarketplace,
    marketplaceAgnosticApi,
    awsApi,
    msftApi,
    offerImportError,
    setOfferImportError,
  };

  return (
    <ApiContext.Provider value={contextValues}>{children}</ApiContext.Provider>
  );
};
