import { fontWeightMediumLarge } from 'utils/fontWeightConstants';
import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  subHeader: {
    color: theme.palette.NEUTRAL300,
    paddingBlock: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.NEUTRAL030}`,
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: fontWeightMediumLarge,
    lineHeight: theme.typography.pxToRem(24),
  },
  helpText: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
  },
}));

const Subsection = ({
  title = 'Customer contact',
  helpText = 'Adding a valid email and phone for the customer contact helps Microsoft sellers evaluate the opportunity better and increases the acceptance rate.',
  children,
}: {
  children: React.ReactNode;
  helpText?: string;
  title?: string;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.subHeader}>
        <div className={classes.title}>{title}</div>
        <span className={classes.helpText}>{helpText}</span>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default Subsection;
