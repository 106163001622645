export const FEATURE_VENDOR_GCP_CO_SELL_ENABLED =
  'feature-vendor-gcp-co-sell-enabled';

export const FEATURE_CO_SELL_V3_UI_ENABLED = 'feature-co-sell-v3-ui-enabled';
const CO_SELL_V2_VENDORS_NOT_MIGRATED = 'co-sell-v2-vendors-not-migrated';
const DEV_TESTING_V3_ONBOARDING_MIGRATION =
  'dev-testing-v3-onboarding-migration';
export const FEATURE_FLAGS_CO_SELL_V3_UI = {
  TOGGLE_CORE_ENABLED: FEATURE_CO_SELL_V3_UI_ENABLED,
  CO_SELL_V2_VENDORS_NOT_MIGRATED,
  DEV_TESTING_V3_ONBOARDING_MIGRATION,
} as const;
