import { createContext } from 'react';

import { authStore } from './Auth';
import { ordersStore } from './Orders';
import { disbursementsStore } from './Disbursements';
import { productsStore } from './Products';
import { userStore } from './User';
import { userManagementStore } from './UserManagement';
import { vendorStore } from './Vendor';
import { meteringStore } from './Metering';
import { contractsStore } from './v4Contracts';

import { hasLoaded, hasSynced } from '../hooks/useStore';

const Auth = createContext(authStore);
const Disbursements = createContext(disbursementsStore);
const Orders = createContext(ordersStore);
const Products = createContext(productsStore);
const User = createContext(userStore);
const UserManagement = createContext(userManagementStore);
const Vendor = createContext(vendorStore);
const Metering = createContext(meteringStore);
const Contracts = createContext(contractsStore);

export {
  Auth,
  Disbursements,
  Orders,
  Products,
  User,
  UserManagement,
  Vendor,
  Metering,
  Contracts,
  hasLoaded,
  hasSynced,
};
