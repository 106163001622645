import {
  CreateBuyerDetailsJSON,
  CreateBuyerJSON,
  CreateCloneJSON,
  CreateDimensionJSON,
  CreateDimensionQuantityJSON,
  CreateEULAJSON,
  CreatePaymentInstallmentJSON,
  CreatePlanTemplateJSON,
  CreatePricingJSON,
  CreateSalesforceJSON,
  CreateUserToNotifyJSON,
  MsftCreateOfferJSON,
} from 'pages/PrivateOffers/pages/Next/msft/api/types/MsftCreateOfferJSON';
import {
  MsftPrivateOffer,
  Pricing,
  UserToNotify,
} from 'pages/PrivateOffers/pages/Next/msft/api/types/MsftPrivateOffer';
import { Optional } from 'utils/optional/optional';
import { Dimension, PlanTemplate } from '../types/MsftPrivateOfferSharedTypes';
import { formatIsoDateTimeAsIsoDate } from 'pages/PrivateOffers/pages/Next/generic/utils/date/dateUtils';

const getSalesforce = (offer: Partial<MsftPrivateOffer>) => {
  return Optional.ofNullable(offer.salesforce)
    .map(
      (sf): CreateSalesforceJSON => ({
        custom_object_id: sf.customObjectId,
        opportunity_id: sf.opportunityId,
      }),
    )
    .orElse(null);
};

const getClone = (offer: Partial<MsftPrivateOffer>) => {
  return Optional.ofNullable(offer.clone)
    .map(
      (c): CreateCloneJSON => ({
        source: c.source,
        originating_offer_id: c.originatingOfferId,
      }),
    )
    .orElse(null);
};

const getBuyerDetails = (
  offer: Partial<MsftPrivateOffer>,
): CreateBuyerDetailsJSON => {
  const buyerDetails = offer.buyerDetails;

  const buyers = (buyerDetails?.buyers || []).map(
    (b): CreateBuyerJSON => ({
      full_name: b.fullName,
      email_address: b.emailAddress,
      title: b.title,
    }),
  );

  return {
    company_name: buyerDetails?.companyName,
    billing_account_id: buyerDetails?.billingAccountId,
    buyers,
  };
};

const getEULA = (offer: Partial<MsftPrivateOffer>): CreateEULAJSON => {
  // TODO - what do do with this now? We have no document urns
  return {
    type: '',
    stored_url: '',
  };
};

const getUsersToNotify = (usersToNotify: UserToNotify[]) => {
  return usersToNotify.map(
    (u): CreateUserToNotifyJSON => ({
      contact_type: '',
      value: u.value,
      email: u.email,
    }),
  );
};

const toDimensionKeyAndCreateDimensionJSON = ([k, v]: [string, Dimension]): [
  string,
  CreateDimensionJSON,
] => {
  const included_quantities = (v.includedQuantities || []).map(
    (iq): CreateDimensionQuantityJSON => ({
      dimension_term: iq.dimensionTerm,
      unlimited_quantity: iq.unlimitedQuantity,
      quantity: iq.quantity,
    }),
  );

  const dimension: CreateDimensionJSON = {
    enabled: v.enabled,
    id: v.id,
    unit_of_measure: v.unitOfMeasure,
    price_per_unit_in_usd: v.pricePerUnitInUsd,
    included_quantities,
  };

  return [k, dimension];
};

const getPricing = (pricing: Pricing | null): CreatePricingJSON | null => {
  return Optional.ofNullable(pricing)
    .map((p) => {
      const paymentInstallments = p.paymentInstallments;

      const payment_installments: CreatePaymentInstallmentJSON =
        Optional.ofNullable(paymentInstallments)
          .map((pi) => ({
            price_per_payment_in_usd: pi.pricePerPaymentInUsd,
          }))
          .orElse(null);

      const dimensions = Object.entries(p.dimensions)
        .map(toDimensionKeyAndCreateDimensionJSON)
        .reduce((acc, v) => {
          acc[v[0]] = v[1];
          return acc;
        }, {});

      return {
        plan_name: p.planName,
        description: p.description,
        contract_duration: p.contractDuration,
        payment_model: p.paymentModel,
        payment_installments,
        dimensions,
      };
    })
    .orElse(null);
};

const getPlanTemplate = (
  planTemplate: PlanTemplate | null,
): CreatePlanTemplateJSON | null => {
  return Optional.ofNullable(planTemplate)
    .map((pt) => ({
      ref: pt.ref,
      display: pt.display,
    }))
    .orElse(null);
};

export const msftPrivateOfferToMsftCreateOfferJSON = (
  offer: Partial<MsftPrivateOffer>,
): MsftCreateOfferJSON => {
  const salesforce = getSalesforce(offer);
  const clone = getClone(offer);
  const buyer_details = getBuyerDetails(offer);
  const eula = getEULA(offer);
  const users_to_notify = getUsersToNotify(offer.usersToNotify || []);
  const pricing = getPricing(offer.pricing);
  const plan_template = getPlanTemplate(offer.planTemplate);

  return {
    offer_type: offer.offerType,
    salesforce,
    vendor_id: offer.vendorId,
    state: offer.state,
    status: offer.status,
    marketplace_fee: offer.marketplaceFee,
    vendor_name: offer.vendorName,
    product_id: offer.productId,
    product_name: offer.productName,
    source: offer.source,
    clone,
    metadata: offer.metadata,
    pre_registration_details: offer.preRegistrationDetails,
    // TODO - this will have to be mapped once the partner object is on the main model
    partner: null,
    name: offer.name,
    description: offer.description,
    buyer_details,
    renewal: offer.renewal,
    eula,
    product_ref: offer.productRef,
    users_to_notify,
    pricing,
    preparer_email: offer.preparerEmail,
    start_on: offer.startOn,
    start_date: formatIsoDateTimeAsIsoDate(offer.startDate),
    end_date: formatIsoDateTimeAsIsoDate(offer.endDate),
    accept_by_date: formatIsoDateTimeAsIsoDate(offer.acceptByDate),
    plan_template,
  };
};
