import { Button } from '@tackle-io/platform-ui';
import { ComponentProps, FC, useMemo } from 'react';
import FormBox from './components/FormBox';
import QuickLinks from './components/QuickLinks/QuickLinks';
import CustomerDetailsFormSection from './CustomerDetailsFormSection/CustomerProfileFormSection';
import DealDetailsFormSection from './DealDetailsFormSection/DealDetailsFormSection';
import SolutionsFormSection from './SolutionsFormSection/SolutionsFormSection';
import TypeOfHelpFormSection from './TypeOfHelpFormSection/TypeOfHelpFormSection';
import PartnersFormSection from './PartnerFormSection/PartnersFormSection';
import TeamMembersFormSection from './TeamMembersFormSection/TeamMembersFormSection';
import { SharedHeader } from 'packages/cosell/src/components';
import { CoSellMsftCreateFormDataId, FormSectionConfig } from './constants';
import {
  ReferralFormProvider,
  useReferralFormContext,
} from './ReferralFormContext';

const ReferralForm = ({ isEdit }: { isEdit?: boolean }) => {
  const { handleSubmit } = useReferralFormContext();

  const quickLinks = useMemo(() => {
    return Object.values(FormSectionConfig).map((section) => ({
      label: section.title,
      id: section.id,
      progress: 0,
    }));
  }, []);

  const title = !isEdit ? 'Create co-sell' : 'Edit co-sell';

  return (
    <form
      onSubmit={handleSubmit((data) => {
        console.log('handleSubmit: ', JSON.stringify(data, null, 2));
        alert(JSON.stringify(data, null, 2));
      })}
    >
      <FormBox
        header={<SharedHeader cloud="microsoft" title={title} />}
        formFields={
          <>
            <CustomerDetailsFormSection />
            <DealDetailsFormSection />
            <SolutionsFormSection />
            <TypeOfHelpFormSection />
            <PartnersFormSection />
            <TeamMembersFormSection />
          </>
        }
        footer={
          <FormBox.Footer>
            <Button
              data-id={`${CoSellMsftCreateFormDataId.ACTION_PREFIX}-cancel`}
              appearance="primary"
              variant="outlined"
              size="small"
            >
              Cancel
            </Button>
            <Button
              data-id={`${CoSellMsftCreateFormDataId.ACTION_PREFIX}-save-draft`}
              appearance="primary"
              disabled
              size="small"
              variant="outlined"
            >
              Save draft
            </Button>
            <Button
              data-id={`${CoSellMsftCreateFormDataId.ACTION_PREFIX}-submit-to-cloud`}
              type="submit"
              appearance="primary"
              variant="contained"
              size="small"
            >
              Submit to cloud
            </Button>
          </FormBox.Footer>
        }
        sidePanel={
          <QuickLinks
            data-id={CoSellMsftCreateFormDataId.QUICK_LINKS}
            sections={quickLinks}
          />
        }
      />
    </form>
  );
};

const WrappedReferralForm: FC<
  ComponentProps<typeof ReferralForm> &
    ComponentProps<typeof ReferralFormProvider>
> = ({ children, initialValues, ...formProps }) => {
  return (
    <ReferralFormProvider initialValues={initialValues}>
      <ReferralForm {...formProps} />
    </ReferralFormProvider>
  );
};

export default WrappedReferralForm;
