import { Box, Grid, makeStyles, Tab, Tabs } from 'vendor/material';
import { useState } from 'react';
import StartAWSOffer from './StartAWSOffer';
import AWSOffersForOpportunity from './AWSOffersForOpportunity';
import AzureOffersForOpportunity from './AzureOffersForOpportunity';
import GCPOffersForOpportunity from './GCPOffersForOpportunity';
import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/aws/api/types/AwsPrivateOffer';
import { Marketplace } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';
import StartMSFTOffer from 'packages/salesforce-canvas/offers/StartMSFTOffer';
import { useAmpliFeatureFlags } from '@tackle-io/tackle-auth-tools';
import { FeatureFlags } from 'pages/PrivateOffers/pages/Next/generic/featureFlags';
import { Tooltip } from '@tackle-io/platform-ui';

const useStyles = makeStyles((_) => ({
  container: {
    height: '100%',
  },
  contents: {
    overflow: 'auto',
  },
}));

const ListOfOffersForOpportunity = ({
  opportunityId,
  offers,
}: {
  opportunityId: string;
  offers: AwsPrivateOffer[];
}) => {
  const classes = useStyles();

  const { flags } = useAmpliFeatureFlags();
  const newOffersUIMsftEnabled =
    flags[FeatureFlags.NewOffersUIMsftEnabled] === 'on';

  const [selectedTab, setSelectedTab] = useState<
    AwsPrivateOffer['marketplace']
  >(Marketplace.Aws);

  const [startingNewAWSOffer, setStartingNewAWSOffer] = useState(false);
  const [startingNewMSFTOffer, setsStartingNewMSFTOffer] = useState(false);

  if (startingNewAWSOffer) {
    return <StartAWSOffer onCancel={() => setStartingNewAWSOffer(false)} />;
  }

  if (startingNewMSFTOffer) {
    return <StartMSFTOffer onCancel={() => setsStartingNewMSFTOffer(false)} />;
  }

  const offersByMarketplace = offers.reduce<
    Record<AwsPrivateOffer['marketplace'], AwsPrivateOffer[]>
  >(
    (acc, offer) => {
      acc[offer.marketplace].push(offer);
      return acc;
    },
    { aws: [], azure: [], gcp: [] },
  );

  return (
    <Box px={2} height="100%">
      <Grid container direction="column" className={classes.container}>
        <Grid item>
          <Tabs
            value={selectedTab}
            onChange={(e, val) => setSelectedTab(val)}
            centered
            indicatorColor="primary"
          >
            <Tab
              value="aws"
              label={`AWS (${offersByMarketplace.aws.length})`}
            />
            {newOffersUIMsftEnabled ? (
              <Tab
                value="azure"
                label={`Microsoft (${offersByMarketplace.azure.length})`}
              />
            ) : (
              <Tooltip content="Coming soon" position="bottom-end">
                <span
                // needed for the tooltip since the next child is disabled and has no click events
                >
                  <Tab
                    value="azure"
                    disabled
                    label={`Microsoft (${offersByMarketplace.azure.length})`}
                  />
                </span>
              </Tooltip>
            )}
            <Tab
              value="gcp"
              disabled
              label={`Google (${offersByMarketplace.gcp.length})`}
            />
          </Tabs>
        </Grid>
        <Grid item xs className={classes.contents}>
          {selectedTab === 'aws' && (
            <AWSOffersForOpportunity awsOffers={offersByMarketplace.aws} />
          )}
          {selectedTab === 'azure' && (
            <AzureOffersForOpportunity
              azureOffers={offersByMarketplace.azure}
            />
          )}
          {selectedTab === 'gcp' && (
            <GCPOffersForOpportunity gcpOffers={offersByMarketplace.gcp} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ListOfOffersForOpportunity;
