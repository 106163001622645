export enum OfferType {
  Direct = 'direct',
  PartnerResale = 'partner_resale',
}

export enum Marketplace {
  Aws = 'aws',
  Azure = 'azure',
  Gcp = 'gcp',
}

export enum ActivitySlug {
  VendorDraftedOffer = 'vendor-drafted-offer',
  VendorCreatedOfferInSalesforce = 'vendor-created-offer-in-salesforce',
  VendorCreatedOffer = 'vendor-created-offer',
  VendorClonedOffer = 'vendor-cloned-offer',
  VendorSentPurchaseInstructions = 'vendor-sent-purchase-instructions',
  VendorReSentPurchaseInstructions = 'vendor-resent-purchase-instructions',
  BuyerOpenedPurchaseInstructions = 'buyer-opened-purchase-instructions',
  BuyerViewedOffer = 'buyer-viewed-private-offer',
  BuyerAcceptedOffer = 'buyer-accepted-private-offer',
  VendorCreatedMarketplaceOfferPending = 'vendor-created-marketplace-offer-pending',
  VendorCreatedMarketplaceOfferSuccess = 'vendor-created-marketplace-offer-success',
  VendorCreatedMarketplaceOfferFailed = 'vendor-created-marketplace-offer-failed',
  VendorChangedMarketplaceOfferExpirationPending = 'marketplace-offer-change-expiration-pending',
  VendorChangedMarketplaceOfferExpirationSuccess = 'marketplace-offer-change-expiration-success',
  VendorChangedMarketplaceOfferExpirationFailed = 'marketplace-offer-change-expiration-failed',
  VendorCancelledMarketplaceOfferPending = 'marketplace-offer-cancel-offer-pending',
  VendorCancelledMarketplaceOfferSuccess = 'marketplace-offer-cancel-offer-success',
  VendorCancelledMarketplaceOfferFailed = 'marketplace-offer-cancel-offer-failed',
  AssociatePBOActivity = 'associated-pbo',
}

export interface Activity {
  activityType: string;
  slug: ActivitySlug;
  createdAt: string;
  metadata: { [k: string]: any };
  userId?: string;
}

export interface UserToNotify {
  email: string;
}

export interface Buyer {
  emailAddress: string;
  fullName?: string;
  title?: string;
}

export interface TackleOffer {
  poId: string;
  marketplace: Marketplace;
  offerType: OfferType;
  offerId: string | null;
  sourceOfferId: string | null; // AWS only
  productId: string | null;
  billingAccountId: string | null;
  buyerCompanyName: string | null;
  offerName: string | null;
  activities: Activity[];
  buyers: Buyer[];
  usersToNotify: UserToNotify[];
  offerMetadata: { [k: string]: string };
  preRegistrationDetails: { [k: string]: string };
  acceptedAt: string | null;
  archivedAt: string | null;
  cancelledAt: string | null;
  createdAt: string | null;
  createdInMarketplaceAt: string | null;
  lastModifiedAt: string | null;
  openedInstructionsAt: string | null;
  sentAt: string | null;
  viewedOfferAt: string | null;
  offerExpirationAt: string | null;
}
