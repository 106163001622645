export function parseArn(roleArn: string | null | undefined) {
  return {
    account: roleArn?.split(':')[4],
    roleName: roleArn?.split('/')[1],
  };
}

export function shortenVendorName(name: string | null | undefined) {
  return name?.toLowerCase()?.replace(/[^A-Za-z0-9-]/gi, '');
}
