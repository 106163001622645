import { Banner, Button } from '@tackle-io/platform-ui';
import { ComponentProps, FC } from 'react';
import { Box, Drawer } from 'vendor/material';
import FormDrawer from '../components/FormDrawer';
import MockCustomerList from './MockCustomerList/MockCustomerList';
import type { CustomerAccountDetailsType } from './customerAccountTransformSchema';
import { CustomerAccountToSelectedCustomerFieldsSchema } from './customerAccountTransformSchema';
import type { SelectedCustomerAccountType } from './sectionSchema';

export const AddCustomerForm = ({
  onClose,
  onSelectItem,
}: {
  onClose: () => void;
  onSelectItem: (customerDetails: SelectedCustomerAccountType) => void;
}) => {
  const handleSelect = (data: CustomerAccountDetailsType) => {
    onSelectItem(CustomerAccountToSelectedCustomerFieldsSchema.parse(data));
  };

  const footerActions = (
    <>
      <Button appearance="primary" variant="outlined" onClick={onClose}>
        Cancel
      </Button>
      <Button
        appearance="primary"
        variant="contained"
        onClick={() => alert('search')}
      >
        Search
      </Button>
    </>
  );
  return (
    <FormDrawer
      footerActions={footerActions}
      title="Add customer"
      onClose={onClose}
    >
      <Box>
        <Banner
          title="Increase Microsoft acceptance rates by selecting a Microsoft managed account."
          type="info"
        />
        {/** for development only - replace this with customer lookup */}
        <MockCustomerList onSelect={handleSelect} />
      </Box>
    </FormDrawer>
  );
};

/** AddCustomerForm wrapped in a Drawer */
export const AddCustomerFormDrawer: FC<
  ComponentProps<typeof Drawer> & ComponentProps<typeof AddCustomerForm>
> = ({ onClose, onSelectItem, open, ...props }) => {
  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      onClose={onClose}
      {...props}
      children={
        <AddCustomerForm onClose={onClose} onSelectItem={onSelectItem} />
      }
    />
  );
};
