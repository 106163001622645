import React from 'react';
import { CoSellActivityFeedItem } from './CoSellActivityFeedItem';
import type { CoSellActivityDetails } from 'packages/cosell/src/types/responses/AceOpportunityEventResponse';
import { Box, Typography } from 'vendor/material';
import { DateTime } from 'luxon';
import useStyles from './CoSellActivityFeed.styles';
import { theme } from 'ui';

interface CoSellActivityFeedProps {
  activities: {
    eventID: string;
    operation: string;
    timestamp: string;
    activityDetails: CoSellActivityDetails;
  }[];
}

/** Generic activity feed for co-sells which can be used to display
 *  various types of activities for opportunities, invitations, etc.
 */
export const CoSellActivityFeed = (props: CoSellActivityFeedProps) => {
  const classes = useStyles();

  return (
    <Box paddingX={theme.spacing(3)}>
      <Typography component="h4" className={classes.widgetTitle}>
        Activity{' '}
        <span className={classes.widgetTitleFade}>
          ({DateTime.local().toFormat('ZZZZ')})
        </span>
      </Typography>
      <div className={classes.activitiesList}>
        {props.activities.map((activity, i) => {
          const activityDetails = activity.activityDetails;

          if (!activityDetails) {
            // TODO: Handle this case properly
            console.warn(
              `Activity details are undefined for eventID: ${activity.eventID}`,
            );
            return null;
          }

          let displayName =
            activityDetails.givenName || activityDetails.familyName || 'User';

          if (activityDetails.givenName && activityDetails.familyName) {
            displayName =
              activityDetails.givenName + ' ' + activityDetails.familyName;
          }
          return (
            <CoSellActivityFeedItem
              key={`${activity.eventID}_${i}`}
              source={activity.operation}
              displayName={displayName}
              message={activityDetails.displayText}
              timestamp={activity.timestamp}
              pictureUrl={activityDetails.pictureUrl}
            />
          );
        })}
      </div>
    </Box>
  );
};
