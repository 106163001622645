import {
  AzureResponseJSON,
  BuyerDetailsJSON,
  BuyerJSON,
  ContractDurationJSON,
  DimensionJSON,
  DimensionQuantityJSON,
  EULAJSON,
  MsftPrivateOfferJSON,
  PlanTemplateJSON,
  POActivityJSON,
  PricingJSON,
  SalesforceJSON,
  ScheduleDetailJSON,
  StateJSON,
  UserToNotifyJSON,
} from 'pages/PrivateOffers/pages/Next/msft/api/types/MsftPrivateOfferJSON';
import { Optional } from 'utils/optional/optional';
import {
  AzureResponse,
  MsftPrivateOffer,
  POActivity,
  Pricing,
  State,
  UserToNotify,
} from 'pages/PrivateOffers/pages/Next/msft/api/types/MsftPrivateOffer';
import {
  ActivitySlug,
  Marketplace,
  OfferType,
} from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';
import {
  Buyer,
  BuyerDetails,
  ContractDuration,
  Dimension,
  DimensionQuantity,
  EULA,
  OfferState,
  OfferStatus,
  PaymentModel,
  PlanTemplate,
  ProductType,
  Salesforce,
  ScheduleDetail,
  Source,
  StartOn,
} from '../types/MsftPrivateOfferSharedTypes';

const toSalesforce = (salesforce: SalesforceJSON | null): Salesforce | null =>
  Optional.ofNullable(salesforce)
    .map((sf) => ({
      customObjectId: sf.custom_object_id,
      opportunityId: sf.opportunity_id,
    }))
    .orElse(null);

const toActivities = (activities: POActivityJSON[]): POActivity[] =>
  activities.map((a) => ({
    slug: a.slug as ActivitySlug,
    userId: a.user_id,
    createdAt: a.created_at,
    metadata: a.metadata,
  }));

const toBuyers = (buyers: BuyerJSON[]): Buyer[] =>
  buyers.map((b) => ({
    fullName: b.full_name,
    emailAddress: b.email_address,
    title: b.title,
  }));

const toBuyerDetails = (
  buyerDetails: BuyerDetailsJSON | null,
): BuyerDetails | null =>
  Optional.ofNullable(buyerDetails)
    .map((bd) => ({
      companyName: bd.company_name,
      billingAccountId: bd.billing_account_id,
      buyers: toBuyers(bd.buyers || []),
    }))
    .orElse(null);

const toEULA = (eula: EULAJSON | null): EULA | null =>
  Optional.ofNullable(eula)
    .map((e) => ({
      type: e.type,
      storedUrl: e.stored_url,
    }))
    .orElse(null);

const toUsersToNotify = (usersToNotify: UserToNotifyJSON[]): UserToNotify[] =>
  usersToNotify.map((u) => ({
    contactType: u.contact_type,
    value: u.value,
    firstName: u.first_name,
    lastName: u.last_name,
    email: u.email,
  }));

const toContractDimension = (cd: ContractDurationJSON): ContractDuration => ({
  type: cd.type,
  frequency: cd.frequency,
});

const toScheduleDetails = (schedules: ScheduleDetailJSON[]): ScheduleDetail[] =>
  schedules.map((s) => ({
    chargeDate: s.charge_date,
    pricePerPaymentInUsd: s.price_per_payment_in_usd,
    note: s.note,
  }));

const toDimensionQuantities = (
  dimensionQuantities: DimensionQuantityJSON[],
): DimensionQuantity[] =>
  dimensionQuantities.map((dq) => ({
    dimensionTerm: dq.dimension_term,
    unlimitedQuantity: dq.unlimited_quantity,
    quantity: dq.quantity,
  }));

const toDimension = (dimension: DimensionJSON): Dimension => {
  const includedQuantities = toDimensionQuantities(
    dimension.included_quantities || [],
  );

  return {
    enabled: dimension.enabled,
    id: dimension.id,
    unitOfMeasure: dimension.unit_of_measure,
    pricePerUnitInUsd: dimension.price_per_unit_in_usd,
    includedQuantities,
  };
};

const toDimensions = (dimensions: {
  [k: string]: DimensionJSON;
}): {
  [k: string]: Dimension;
} =>
  Object.entries(dimensions).reduce(
    (acc: { [k: string]: Dimension }, entry: [string, DimensionJSON]) => {
      const key = entry[0];
      const dimension = entry[1];

      acc[key] = toDimension(dimension);

      return acc;
    },
    {},
  );

const pricingJSONToPricing = (pricing: PricingJSON): Pricing => {
  const contractDuration = Optional.ofNullable(pricing.contract_duration)
    .map(toContractDimension)
    .orElse(null);

  const paymentInstallments = Optional.ofNullable(pricing.payment_installments)
    .map((pi) => ({
      pricePerPaymentInUsd: pi.price_per_payment_in_usd,
    }))
    .orElse(null);

  const paymentSchedule = toScheduleDetails(pricing.payment_schedule || []);
  const dimensions = toDimensions(pricing.dimensions || {});

  return {
    planRef: pricing.plan_ref,
    planName: pricing.plan_name,
    description: pricing.description,
    contractDuration,
    paymentModel: pricing.payment_model as PaymentModel,
    paymentInstallments,
    paymentSchedule,
    dimensions,
  };
};

const toPricing = (pricing: PricingJSON | null): Pricing | null =>
  Optional.ofNullable(pricing).map(pricingJSONToPricing).orElse(null);

const toOfferState = (offerState: StateJSON): State => {
  return Optional.ofNullable(offerState)
    .map((os) => {
      const draft = Optional.ofNullable(os.draft)
        .map((d) => ({ rawMessage: d.raw_message }))
        .orElse(null);

      const submitted = Optional.ofNullable(os.submitted)
        .map((s) => ({ rawMessage: s.raw_message }))
        .orElse(null);

      const accepted = Optional.ofNullable(os.accepted)
        .map((a) => ({ rawMessage: a.raw_message }))
        .orElse(null);

      const currentCloud = Optional.ofNullable(os.current_cloud)
        .map((cc) => ({ rawMessage: cc.raw_message }))
        .orElse(null);

      return {
        draft,
        submitted,
        accepted,
        currentCloud,
        lastModified: os.last_modified,
      };
    })
    .orElse(null);
};

const getPlanTemplate = (
  planTemplate: PlanTemplateJSON | null,
): PlanTemplate | null => {
  return Optional.ofNullable(planTemplate)
    .map((pt) => ({
      ref: pt.ref,
      display: pt.display,
    }))
    .orElse(null);
};

const getAzureResponse = (
  azureResponse: AzureResponseJSON | null,
): AzureResponse | null => {
  return Optional.ofNullable(azureResponse)
    .map((ar) => ({
      jobId: ar.job_id,
      offerId: ar.offer_id,
      resourceUri: ar.resource_uri,
      rawResponse: ar.raw_response,
    }))
    .orElse(null);
};

export const msftPrivateOfferJSONToMsftPrivateOffer = (
  offer: MsftPrivateOfferJSON,
): MsftPrivateOffer => {
  const salesforce = toSalesforce(offer.salesforce);
  const activities = toActivities(offer.activities || []);
  const buyerDetails = toBuyerDetails(offer.buyer_details);
  const eula = toEULA(offer.eula);
  const pricing = toPricing(offer.pricing);
  const offerState = toOfferState(offer.offer_state);
  const usersToNotify = toUsersToNotify(offer.users_to_notify || []);
  const planTemplate = getPlanTemplate(offer.plan_template);
  const azureResponse = getAzureResponse(offer.azure_response);

  return {
    poId: offer.po_id,
    marketplace: Marketplace.Azure,
    acceptedAt: offer.accepted_at,
    acceptedSource: offer.accepted_source,
    archivedAt: offer.archived_at,
    createdAt: offer.created_at,
    cancelledAt: offer.cancelled_at,
    lastModifiedAt: offer.last_modified_at,
    lastUpdateSource: offer.last_update_source,
    viewedOfferAt: offer.viewed_offer_at,
    sentAt: offer.sent_at,
    submittedAt: offer.submitted_at,
    openedInstructionsAt: offer.opened_instructions_at,
    marketplaceAcceptanceLink: offer.marketplace_acceptance_link,
    salesforce,
    vendorId: offer.vendor_id,
    vendorName: offer.vendor_name,
    productContactEmail: offer.product_contact_email,
    state: offer.state as OfferState,
    status: offer.status as OfferStatus,
    offerType: offer.offer_type as OfferType,
    marketplaceFee: offer.marketplace_fee,
    activities,
    archived: offer.archived,
    version: offer.version,
    productId: offer.product_id,
    productRef: offer.product_ref,
    productName: offer.product_name,
    source: offer.source as Source,
    marketplaceCreatedAt: offer.marketplace_created_at,
    name: offer.name,
    description: offer.description,
    buyerDetails,
    renewal: offer.renewal,
    eula,
    usersToNotify,
    pricing,
    preparerEmail: offer.preparer_email,
    startOn: offer.start_on as StartOn,
    startDate: offer.start_date,
    endDate: offer.end_date,
    acceptByDate: offer.accept_by_date,
    offerRef: offer.offer_ref,
    offerState,
    metadata: offer.metadata,
    productType: offer.product_type as ProductType,
    preRegistrationDetails: offer.pre_registration_details,
    uiState: offer.ui_state,
    totalContractValueGrossAmount: offer.total_contract_value_gross_amount,
    totalContractValueNetAmount: offer.total_contract_value_net_amount,
    planTemplate,
    azureResponse,
  };
};
