import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { Link, Banner, Button, Loader } from '@tackle-io/platform-ui';
import { makeStyles, Grid, Typography, Box, Card } from 'vendor/material';
import {
  ProgressClock,
  Lock,
  // Email,
  Sale,
  ChevronRight,
  ChevronDown,
} from 'mdi-material-ui';
import { ampli } from 'utils/analytics/ampli';
import { useAmpliFeatureFlags } from '@tackle-io/tackle-auth-tools';
import { INTEGRATIONS } from '../../constants';
import { shortenVendorName } from 'utils/cft';
// import AdminInstructionsModal from './components/AdminInstructionsModal';
import GeneralListing from './assets/GeneralListing';
import GeneralGrowth from './assets/GeneralGrowth';
import ProductTackleOffers from './assets/ProductTackleOffers';
import ProductCoSell from './assets/ProductCoSell';
import HandshakeIcon from './assets/HandshakeIcon';
import AwsLogo from './assets/AwsLogo';
import { useAwsIntegrationsVendorOnboardingRequirementsQuery } from 'generated/graphql';

const useStyles = makeStyles((theme) => ({
  breadcrumbIntegrations: {
    paddingBottom: '20px',
    marginLeft: theme.spacing(2),
    color: theme.palette.NEUTRAL900,
    textDecoration: 'none',
    '& > a': {
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.BLUE400,
      },
      '&:visited': {
        textDecoration: 'none',
        color: theme.palette.NEUTRAL900,
      },
    },
  },
  breadcrumbDivider: {
    marginLeft: theme.spacing(3),
  },
  sendAdminButton: {
    border: '1px solid #C1C7D0',
    marginRight: '30px',
  },
  card: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  icon: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.NEUTRAL100,
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  permissions: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.palette.NEUTRAL300,
    marginRight: theme.spacing(1),
    display: 'flex',
    alignContent: 'center',
    '& > b': {
      marginRight: theme.spacing(1.5),
    },
  },
  headerGrid: {
    marginLeft: theme.spacing(2),
    paddingLeft: theme.typography.pxToRem(100),
    marginTop: theme.typography.pxToRem(50),
    marginBottom: theme.typography.pxToRem(50),
  },
  headerTitle: {
    color: theme.palette.NEUTRAL700,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  headerAwsConnectTitle: {
    color: theme.palette.NEUTRAL700,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  getConnected: {
    color: theme.palette.NEUTRAL300,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
  },
  cardContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  successfullConnectionText: {
    color: theme.palette.TEAL900,
    fontSize: theme.typography.pxToRem(16),
    marginLeft: theme.spacing(2),
  },
  emailIcon: {
    color: theme.palette.NEUTRAL700,
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
  },
  smallCopyBold: {
    color: theme.palette.NEUTRAL700,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
  },
  smallCopy: {
    color: theme.palette.NEUTRAL300,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
  },
  smallLabel: {
    color: '#0052CC',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
  },
  standardCopyBold: {
    color: '#0052CC',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
  },
  standardCopy: {
    color: theme.palette.TEAL900,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
  },
  stepText: {
    color: theme.palette.NEUTRAL700,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  stepNumber: {
    marginRight: theme.spacing(1),
  },
  marginLeft20: {
    marginLeft: theme.spacing(2),
  },
}));

export const AWS_VENDOR_REQUIREMENTS_QUERY = gql`
  query AwsIntegrationsVendorOnboardingRequirements {
    currentUser {
      id
      vendor {
        id
        name
        external_id
      }
    }
    awsRegistrationToken
  }
`;

const PermissionsTimeToCompleteTitle = ({
  permissions,
  timeToComplete,
}: {
  permissions?: string;
  timeToComplete?: string;
}) => {
  const classes = useStyles();
  const lockIcon = (
    <span className={classes.icon}>
      <Lock fontSize={'inherit'} />
    </span>
  );
  const progressClockIcon = (
    <span className={classes.icon}>
      <ProgressClock fontSize={'inherit'} />
    </span>
  );
  return (
    <Box ml={2} mt={2}>
      <div className={classes.permissions}>
        {permissions && (
          <>
            {lockIcon}Permissions required:&nbsp;<b>{permissions}</b>
          </>
        )}
        {timeToComplete && (
          <>
            {progressClockIcon}Time to complete:&nbsp;<b>{timeToComplete}</b>
          </>
        )}
      </div>
    </Box>
  );
};

const AwsIntegrations = () => {
  const classes = useStyles();
  const [showMarketplaceSteps, setShowMarketplaceSteps] = useState(false);
  const [showCosellSteps, setShowCosellSteps] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const { data } = useAwsIntegrationsVendorOnboardingRequirementsQuery();

  const hasRequiredData = (): boolean => {
    return !!(
      data?.awsRegistrationToken &&
      data?.currentUser?.vendor?.name &&
      data?.currentUser?.vendor?.external_id
    );
  };

  const generateUrl = () => {
    const stackName = 'Tackle-Resources';
    const templateURL =
      'https://tackle-templates.s3.us-west-2.amazonaws.com/consolidated/consolidated.yaml';

    const searchParams = new URLSearchParams({
      stackName,
      templateURL,
      param_RegistrationToken: data?.awsRegistrationToken || '',
      param_CompanyName:
        shortenVendorName(data?.currentUser?.vendor?.name) || '',
      param_ExternalId: data?.currentUser?.vendor?.external_id || '',
    });

    return `https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?${searchParams.toString()}`;
  };

  const { loading: ampliLoading, flags: ampliFlags } = useAmpliFeatureFlags();
  if (ampliLoading) {
    return <Loader />;
  }
  const isAwsCloudConnectorEnabled =
    ampliFlags['cloud-connector-aws-integrations'] === 'on';

  const handleToggleMarketplaceSteps = () => {
    setShowMarketplaceSteps((prevShowSteps) => !prevShowSteps);
  };

  const handleToggleCosellSteps = () => {
    setShowCosellSteps((prevShowSteps) => !prevShowSteps);
  };

  // const handleOpenModal = () => {
  //   setIsModalOpen(true);
  // };

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };

  if (!isAwsCloudConnectorEnabled) {
    return <Redirect to="/integrations" />;
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        data-testid="aws-integrations"
      >
        <Grid item xs={12} className={classes.headerGrid}>
          <span className={classes.breadcrumbDivider}>{'<'}</span>
          <Link
            to="/integrations"
            aria-label="breadcrumb"
            className={classes.breadcrumbIntegrations}
          >
            Integrations
          </Link>
          <Grid container>
            <Grid item className={classes.marginLeft20}>
              <AwsLogo />
            </Grid>
            <Grid item>
              <Typography className={classes.headerTitle} variant="h4">
                AWS
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Card className={classes.cardContainer}>
              <Box ml={2}>
                <Typography
                  className={classes.headerAwsConnectTitle}
                  variant="h5"
                >
                  Connect to AWS
                </Typography>
                <PermissionsTimeToCompleteTitle
                  permissions="AWS admin"
                  timeToComplete="5 minutes"
                />
                <Typography className={classes.getConnected}>
                  Get connected to be able to:
                </Typography>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <Box display="flex" alignItems="center" mr={5} ml={2}>
                      <GeneralListing />
                      <Box ml={1}>
                        <Typography className={classes.smallCopyBold}>
                          List products
                        </Typography>
                        <Typography className={classes.smallCopy}>
                          Get listed on AWS Marketplace
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box display="flex" alignItems="center" mr={5}>
                      <ProductCoSell />
                      <Box ml={1}>
                        <Typography className={classes.smallCopyBold}>
                          Co-sell with ACE
                        </Typography>
                        <Typography className={classes.smallCopy}>
                          Register opportunities with ACE
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box display="flex" alignItems="center" mr={5}>
                      <ProductTackleOffers />
                      <Box ml={1}>
                        <Typography className={classes.smallCopyBold}>
                          Transact
                        </Typography>
                        <Typography className={classes.smallCopy}>
                          Create private offers
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box display="flex" alignItems="center">
                      <GeneralGrowth />
                      <Box ml={1}>
                        <Typography className={classes.smallCopyBold}>
                          Track performance
                        </Typography>
                        <Typography className={classes.smallCopy}>
                          Monitor critical metrics
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Card variant="outlined" className={classes.card}>
                <Grid container direction="column" spacing={2}>
                  <Box mt={4} ml={2}>
                    <Grid item xs={10}>
                      <Typography className={classes.successfullConnectionText}>
                        <b>
                          To ensure a successful connection, complete these
                          steps first.
                        </b>
                      </Typography>
                    </Grid>
                  </Box>
                  <Box ml={4} mr={4} mb={4}>
                    <Grid item xs={12}>
                      <Banner title="You must be signed into your AWS Marketplace account as the administrator." />
                    </Grid>
                  </Box>
                  <Box ml={2} mb={2}>
                    <Grid item xs={12} className={classes.marginLeft20}>
                      <Box display="flex" alignItems="center">
                        <Sale className={classes.emailIcon} />
                        <Box>
                          <Typography className={classes.standardCopy}>
                            Marketplace operations
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item className={classes.marginLeft20}>
                      <Typography
                        className={classes.standardCopyBold}
                        style={{ color: '#253858' }}
                      >
                        Setup your seller account.
                      </Typography>
                    </Grid>

                    <Grid item xs={10}>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleToggleMarketplaceSteps}
                        endIcon={
                          showMarketplaceSteps ? (
                            <ChevronDown className={classes.smallLabel} />
                          ) : (
                            <ChevronRight className={classes.smallLabel} />
                          )
                        }
                      >
                        <span className={classes.smallLabel}>
                          {showMarketplaceSteps ? 'Hide Steps' : 'See Steps'}
                        </span>
                      </Button>
                      {showMarketplaceSteps && (
                        <Box ml={2}>
                          <Typography className={classes.stepText}>
                            <span className={classes.stepNumber}>1.</span>
                            Identify which account
                            <Box mt={1} />
                            <Typography className={classes.stepText}>
                              This account will be used when publishing your
                              listings and managing disbursement of sales. We
                              recommend creating a brand new AWS account that is
                              linked to your master account. By creating a new
                              account you are isolating your production and
                              development accounts from your Tackle managed
                              listings. You can create and link accounts in{' '}
                              <Link
                                showExternalIcon
                                to={
                                  'https://console.aws.amazon.com/organizations/home?#/accounts'
                                }
                                external
                              >
                                AWS Organizations.
                              </Link>
                              <Box mt={1} />
                              Once created, login to this account and complete
                              the next steps.
                            </Typography>
                          </Typography>
                          <Typography className={classes.stepText}>
                            <span className={classes.stepNumber}>2.</span>
                            Ensure that the account has access to create roles
                            and stacks, and configure financial services.
                          </Typography>
                          <Typography className={classes.stepText}>
                            <span className={classes.stepNumber}>3.</span>
                            Accept the{' '}
                            <Link
                              showExternalIcon
                              to={
                                'https://aws.amazon.com/marketplace/management/seller-settings/register/'
                              }
                              external
                            >
                              AWS Terms and Services
                            </Link>
                          </Typography>
                          <Typography className={classes.stepText}>
                            <span className={classes.stepNumber}>4.</span>
                            Complete the{' '}
                            <Link
                              showExternalIcon
                              to={
                                'https://aws.amazon.com/marketplace/management/seller-settings/income-tax/us/status'
                              }
                              external
                            >
                              Tax Information Interview
                            </Link>
                          </Typography>
                          <Typography className={classes.stepText}>
                            <span className={classes.stepNumber}>5.</span>
                            Complete the{' '}
                            <Link
                              showExternalIcon
                              to={
                                'https://aws.amazon.com/marketplace/management/seller-settings/account/bank'
                              }
                              external
                            >
                              Banking Information
                            </Link>
                          </Typography>
                          <Typography className={classes.stepText}>
                            <span className={classes.stepNumber}>6.</span>
                            Complete the{' '}
                            <Link
                              showExternalIcon
                              to={
                                'https://aws.amazon.com/marketplace/management/seller-settings/account'
                              }
                              external
                            >
                              Company Public Profile
                            </Link>
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </Box>
                  <Box mt={2} ml={2}>
                    <Grid item xs={12} className={classes.marginLeft20}>
                      <Box display="flex" alignItems="center">
                        <HandshakeIcon />
                        <Box>
                          <Typography className={classes.standardCopy}>
                            Co-sell operations
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} className={classes.marginLeft20}>
                      <Typography
                        className={classes.standardCopyBold}
                        style={{ color: '#253858' }}
                      >
                        Link your Partner Central account to Marketplace.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleToggleCosellSteps}
                        endIcon={
                          showCosellSteps ? (
                            <ChevronDown className={classes.smallLabel} />
                          ) : (
                            <ChevronRight className={classes.smallLabel} />
                          )
                        }
                      >
                        <span className={classes.smallLabel}>
                          {showCosellSteps ? 'Hide Steps' : 'See Steps'}
                        </span>
                      </Button>
                      {showCosellSteps && (
                        <Box ml={2}>
                          <Typography className={classes.stepText}>
                            <span className={classes.stepNumber}>1.</span>
                            Sign in to{' '}
                            <Link
                              showExternalIcon
                              to={
                                'https://partnercentral.awspartner.com/partnercentral2/s/acecrmintegration'
                              }
                              external
                            >
                              Partner Central
                            </Link>
                            ,with an Alliance Lead or Cloud Admin role.
                          </Typography>
                          <Typography className={classes.stepText}>
                            <span className={classes.stepNumber}>2.</span>
                            In the <b>Account Linking section </b>on the
                            homepage, click Link Account.
                          </Typography>
                          <Typography className={classes.stepText}>
                            <span className={classes.stepNumber}>3.</span>
                            On the <b>Account Linking page,</b> choose{' '}
                            <b>Link Account.</b> No, this isn't a duplicate of
                            step 2.
                          </Typography>
                          <Typography className={classes.stepText}>
                            <span className={classes.stepNumber}>4.</span>
                            Select<b> IAM user,</b> and then enter the AWS
                            Account ID for your AWS account.
                          </Typography>
                          <Typography className={classes.stepText}>
                            <span className={classes.stepNumber}>5.</span>
                            Click <b>Next</b>, and then sign in to the AWS
                            account.
                          </Typography>
                          <Typography className={classes.stepText}>
                            <span className={classes.stepNumber}>6.</span>
                            Click<b> Allow </b>to authorize the connection
                            between your AWS Partner Central and AWS accounts.
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </Box>
                  <Grid item xs={12}>
                    <Box
                      ml={1}
                      mb={2}
                      mr={2}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Link to={generateUrl()} external>
                        <Button
                          appearance="primary"
                          disabled={!hasRequiredData()}
                          onClick={() => {
                            ampli.buttonClicked({
                              button_location: 'integrations-connect-to-aws',
                              button_name: 'connect',
                              button_product_area: INTEGRATIONS,
                            });
                          }}
                        >
                          Connect
                        </Button>
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
              {/* <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                ml={2}
                mt={4}
              >
                <Box display="flex" alignItems="center" ml={2}>
                  <Email className={classes.emailIcon} />
                  <Typography className={classes.smallCopyBold}>
                    Send instructions to your admin
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  style={{ border: '1px solid #C1C7D0', marginRight: '30px' }}
                  startIcon={
                    <Email
                      className={classes.emailIcon}
                      style={{ color: '#0052CC' }}
                    />
                  }
                  onClick={handleOpenModal}
                >
                  <Typography className={classes.standardCopyBold}>
                    Send admin instructions
                  </Typography>
                </Button>
              </Box>
              <Box mb={4} ml={4}>
                <Typography className={classes.smallCopy}>
                  If your admin does not have a Tackle account, they can get you
                  connected outside of Tackle.
                </Typography>
              </Box> */}
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {/* <AdminInstructionsModal open={isModalOpen} onClose={handleCloseModal} /> */}
    </>
  );
};

export default AwsIntegrations;
