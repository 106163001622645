import type { FC, ComponentProps } from 'react';
import { makeStyles, Box } from 'vendor/material';
import { Button, Card } from '@tackle-io/platform-ui';
import { fontWeightBold } from 'utils/fontWeightConstants';

const useStyles = makeStyles((theme) => ({
  container: {
    scrollMarginTop: theme.spacing(3),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  blockMessage: {
    paddingBlock: theme.spacing(2),
    paddingInline: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
    margin: 0,
    lineHeight: theme.typography.pxToRem(20),
  },
  title: {
    color: theme.palette.BLACK,
  },
  addButton: {
    fontWeight: fontWeightBold,
  },
}));

const FormSectionCard = ({
  children,
  sectionId,
  title,
  ...cardProps
}: ComponentProps<typeof Card> & { sectionId?: string }) => {
  const classes = useStyles();
  return (
    <div id={sectionId} style={{ scrollMarginTop: '2rem' }}>
      <Card
        title={<span className={classes.title}>{title}</span>}
        {...cardProps}
      >
        <Box className={classes.content}>{children}</Box>
      </Card>
    </div>
  );
};

const BlockMessage: FC = ({ children }) => {
  const classes = useStyles();
  return <p className={classes.blockMessage}>{children}</p>;
};

const AddButton = (buttonProps: ComponentProps<typeof Button>) => {
  return (
    <Box>
      <Button
        appearance="primary"
        variant="text"
        fullWidth={false}
        style={{ fontWeight: fontWeightBold }}
        {...buttonProps}
      />
    </Box>
  );
};

FormSectionCard.AddButton = AddButton;
FormSectionCard.BlockMessage = BlockMessage;

export default FormSectionCard;
