import { Banner } from '@tackle-io/platform-ui';
import FormSectionCard from '../components/FormSectionCard';
import { FormSectionConfig } from '../constants';
import { AssignmentIcon } from 'packages/cosell/assets';

export const DealDetailsFormSection = () => {
  return (
    <FormSectionCard
      title={FormSectionConfig.dealDetails.title}
      subtitle={null}
      icon={<AssignmentIcon />}
      data-id={FormSectionConfig.dealDetails.dataId}
      sectionId={FormSectionConfig.dealDetails.id}
    >
      <Banner
        title="Accurate deal values over Microsoft's $25,000 minimum can increase acceptance rates by up to 15%."
        type="info"
      />
      <FormSectionCard.BlockMessage>
        Adding fields here...
      </FormSectionCard.BlockMessage>
    </FormSectionCard>
  );
};

export default DealDetailsFormSection;
