import { useCallback } from 'react';
import { offersApi } from 'pages/PrivateOffers/pages/Next/generic/api/offerAPIs';
import {
  AwsPrivateOffer,
  Pricing,
} from 'pages/PrivateOffers/pages/Next/aws/api/types/AwsPrivateOffer';
import {
  awsPrivateOfferJSONToAwsPrivateOffer,
  toPricing,
} from 'pages/PrivateOffers/pages/Next/aws/api/transformers/awsPrivateOfferJSONToAwsPrivateOffer';
import { awsPrivateOfferToAwsPrivateOfferJSON } from 'pages/PrivateOffers/pages/Next/aws/api/transformers/awsPrivateOfferToAwsPrivateOfferJSON';
import { AwsPrivateOfferRequestJSON } from 'pages/PrivateOffers/pages/Next/aws/api/types/AwsPrivateOfferRequestJSON';
import { AwsPrivateOfferResponseJSON } from 'pages/PrivateOffers/pages/Next/aws/api/types/AwsPrivateOfferResponseJSON';
import { AwsPrivateOffersResponseJSON } from 'pages/PrivateOffers/pages/Next/aws/api/types/AwsPrivateOffersResponseJSON';
import { OfferAPIKey } from 'pages/PrivateOffers/pages/Next/generic/ApiContext/offerAPIKey';
import {
  ContextValueSetters,
  ImportValidationError,
  NewOfferOfferSubmissionErrorsKey,
  OfferApiFunctions,
  OfferSubmissionError,
} from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import { PricingJSON } from 'pages/PrivateOffers/pages/Next/aws/api/types/AwsPrivateOfferJSON';
import { Agreement } from 'pages/PrivateOffers/pages/Next/aws/api/types/Agreement';
import { AgreementsResponseJSON } from 'pages/PrivateOffers/pages/Next/aws/api/types/AgreementsResponseJSON';
import { agreementJSONToAgreements } from 'pages/PrivateOffers/pages/Next/aws/api/transformers/agreementJSONToAgreements';
import useOfferApi from 'pages/PrivateOffers/pages/Next/generic/hooks/useOfferApi';
import { addAction, addError } from 'utils/monitor/datadog';
import { byAcceptanceDateDescending } from 'pages/PrivateOffers/pages/Next/generic/utils/offer/offerUtils';

export type UseAwsPrivateOfferApi = OfferApiFunctions<AwsPrivateOffer> & {
  getOfferForAgreement: (offerId: string) => Promise<AwsPrivateOffer | null>;
  getAgreementMappedAsPrivateOffer: (offerId: string) => Promise<any>;
  getOffersForOpportunity: (
    opportunityId: string,
  ) => Promise<AwsPrivateOffer[] | null>;
  createOffer: (
    offer: Partial<AwsPrivateOffer>,
    createInMarketplace: boolean,
  ) => Promise<AwsPrivateOffer | null>;
  getVendorCurrencies: () => Promise<string[]>;
  getAwsMarketplacePricing: (encryptedProductId: string) => Promise<Pricing>;
  getAgreements: (
    buyerAccountNumber: string,
    offerId?: string,
    activeOnly?: boolean,
  ) => Promise<Agreement[]>;
  importOffer: (
    id: string,
    buyerCompanyName: string,
    opportunityId?: string,
  ) => Promise<AwsPrivateOffer | null>;
};

const convertPrivateOfferResponseToPrivateOffer = async (
  response: AwsPrivateOfferResponseJSON | null,
) =>
  response
    ? awsPrivateOfferJSONToAwsPrivateOffer(response.private_offer)
    : null;

const convertPrivateOffersResponseToPrivateOffers = async (
  response: AwsPrivateOffersResponseJSON | null,
) =>
  response
    ? response.private_offers?.map(awsPrivateOfferJSONToAwsPrivateOffer)
    : null;

const convertToPricing = async (responseJson: PricingJSON | null) =>
  toPricing(responseJson);

const convertToAgreements = async (responseJson: AgreementsResponseJSON) =>
  agreementJSONToAgreements(responseJson.entitlements);

const convertPrivateOfferResponseTakingLatestAcceptedOffer = async (
  response: AwsPrivateOffersResponseJSON | null,
) => {
  const privateOffers =
    (await convertPrivateOffersResponseToPrivateOffers(response)) || [];

  const offersSortedByAcceptanceDateDescending = [...privateOffers].sort(
    byAcceptanceDateDescending,
  );

  return offersSortedByAcceptanceDateDescending?.at(0);
};

const useAwsPrivateOfferApi = (
  accessTokenProvider: () => Promise<string>,
  contextValueSetters: ContextValueSetters,
): UseAwsPrivateOfferApi => {
  const { setOfferSubmissionErrors, setOfferImportError } = contextValueSetters;

  const {
    stateTrackingGet,
    stateTrackingSubmit,
    stateTrackingSubmitWithEmptyResponse,
  } = useOfferApi(contextValueSetters);

  const handleOfferSubmissionError = useCallback(
    (poId: string) =>
      async (response): Promise<null> => {
        const body = await response.response.text();
        const offerSubmissionError = JSON.parse(body) as OfferSubmissionError;

        setOfferSubmissionErrors((previousOfferSubmissionErrors) => ({
          ...previousOfferSubmissionErrors,
          [poId]: offerSubmissionError,
        }));
        addError(offerSubmissionError);

        return null;
      },
    [setOfferSubmissionErrors],
  );

  const handleOfferImportError = useCallback(
    async (response): Promise<null> => {
      const body = await response.response.text();
      const offerImportError = JSON.parse(body) as ImportValidationError;

      setOfferImportError(offerImportError);
      addError(offerImportError);

      return null;
    },
    [setOfferImportError],
  );

  const fetchOffer = useCallback(
    (poId: string, silent: boolean = false) =>
      stateTrackingGet(
        silent ? OfferAPIKey.Silent : OfferAPIKey.Offer,
        () =>
          offersApi(accessTokenProvider).get(
            `public/v2/private-offers/${poId}`,
          ),
        convertPrivateOfferResponseToPrivateOffer,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getOffer = useCallback(
    async (poId: string): Promise<AwsPrivateOffer | null> => fetchOffer(poId),
    [fetchOffer],
  );

  const getOfferSilently = useCallback(
    async (poId: string): Promise<AwsPrivateOffer | null> =>
      fetchOffer(poId, true),
    [fetchOffer],
  );

  const getOfferForAgreement = useCallback(
    (offerId: string): Promise<AwsPrivateOffer | null> =>
      stateTrackingGet(
        OfferAPIKey.AgreementOffer,
        () =>
          offersApi(accessTokenProvider).get(
            `public/v2/private-offers?offer_ref=${offerId}&status=any`,
          ),
        convertPrivateOfferResponseTakingLatestAcceptedOffer,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getAgreementMappedAsPrivateOffer = useCallback(
    (offerId: string): Promise<any> =>
      stateTrackingGet(
        OfferAPIKey.Silent,
        () =>
          offersApi(accessTokenProvider).get(
            `public/v2/marketplace/aws/offers/${offerId}`,
          ),
        convertPrivateOfferResponseToPrivateOffer,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getOffersForOpportunity = useCallback(
    async (opportunityId: string): Promise<AwsPrivateOffer[] | null> =>
      stateTrackingGet(
        OfferAPIKey.OffersForOpportunity,
        () =>
          offersApi(accessTokenProvider).get(
            `public/v2/private-offers?salesforce_opportunity_id=${opportunityId}`,
          ),
        convertPrivateOffersResponseToPrivateOffers,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const createOffer = useCallback(
    async (
      offer: Partial<AwsPrivateOffer>,
      createInMarketplace: boolean,
    ): Promise<AwsPrivateOffer | null> => {
      const json: AwsPrivateOfferRequestJSON = {
        create_in_marketplace: createInMarketplace,
        send_buyer_instructions: false,
        dry_run: false,
        private_offer: awsPrivateOfferToAwsPrivateOfferJSON(offer),
      };

      addAction('createOffer', { offer: json });

      return stateTrackingSubmit(
        OfferAPIKey.Offer,
        () =>
          offersApi(accessTokenProvider).post('public/v2/private-offers', {
            json,
          }),
        convertPrivateOfferResponseToPrivateOffer,
        handleOfferSubmissionError(NewOfferOfferSubmissionErrorsKey),
      );
    },
    [stateTrackingSubmit, accessTokenProvider, handleOfferSubmissionError],
  );

  const updateOffer = useCallback(
    async (
      poId: string,
      updatedOffer: Partial<AwsPrivateOffer>,
      createInMarketplace: boolean,
    ): Promise<AwsPrivateOffer | null> => {
      const json: AwsPrivateOfferRequestJSON = {
        create_in_marketplace: createInMarketplace,
        send_buyer_instructions: false,
        dry_run: false,
        private_offer: awsPrivateOfferToAwsPrivateOfferJSON(updatedOffer),
      };

      addAction('updateOffer', { offer: json });

      return stateTrackingSubmit(
        OfferAPIKey.Offer,
        () =>
          offersApi(accessTokenProvider).put(
            `public/v2/private-offers/${poId}`,
            { json },
          ),
        convertPrivateOfferResponseToPrivateOffer,
        handleOfferSubmissionError(poId),
      );
    },
    [stateTrackingSubmit, accessTokenProvider, handleOfferSubmissionError],
  );

  const sendBuyerInstructions = useCallback(
    async (poId: string): Promise<void> =>
      stateTrackingSubmitWithEmptyResponse(
        OfferAPIKey.SendBuyerInstructions,
        () =>
          offersApi(accessTokenProvider).post(
            `public/v2/private-offers/${poId}/send-buyer-instructions`,
          ),
      ),
    [stateTrackingSubmitWithEmptyResponse, accessTokenProvider],
  );

  const cancelOffer = useCallback(
    async (poId: string): Promise<void> =>
      stateTrackingSubmitWithEmptyResponse(OfferAPIKey.CancelOffer, () =>
        offersApi(accessTokenProvider).post(
          `public/v2/private-offers/${poId}/cancellation`,
        ),
      ),
    [stateTrackingSubmitWithEmptyResponse, accessTokenProvider],
  );

  const archiveOffer = useCallback(
    async (poId: string): Promise<void> =>
      stateTrackingSubmitWithEmptyResponse(OfferAPIKey.ArchiveOffer, () =>
        offersApi(accessTokenProvider).delete(
          `public/v2/private-offers/${poId}`,
        ),
      ),
    [stateTrackingSubmitWithEmptyResponse, accessTokenProvider],
  );

  const getVendorCurrencies = useCallback(
    async (): Promise<string[]> =>
      stateTrackingGet(OfferAPIKey.VendorCurrencies, () =>
        offersApi(accessTokenProvider).get(
          `public/v2/marketplace/aws/seller/allowed-currencies`,
        ),
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getAwsMarketplacePricing = useCallback(
    async (encryptedProductId: string): Promise<Pricing> =>
      stateTrackingGet(
        OfferAPIKey.MarketplacePricing,
        () =>
          offersApi(accessTokenProvider).get(
            `public/v2/marketplace/aws/products/${encryptedProductId}/pricing`,
          ),
        convertToPricing,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getAgreements = useCallback(
    (
      buyerAccountNumber: string = '',
      offerId: string = '',
      activeOnly: boolean = false,
    ): Promise<Agreement[]> => {
      const activeSearchParam = activeOnly ? '&status=ACTIVE' : '';
      const buyerAccountSearchParam = buyerAccountNumber
        ? `&buyer_billing_account_ref=${buyerAccountNumber}`
        : '';

      const offerIdSearchParam = offerId ? `&offerid=${offerId}` : '';
      const url = `public/v2/marketplace/aws/entitlements?limit=20${activeSearchParam}${buyerAccountSearchParam}${offerIdSearchParam}`;

      return stateTrackingGet(
        OfferAPIKey.ActiveAgreements,
        () => offersApi(accessTokenProvider).get(url),
        convertToAgreements,
      );
    },
    [stateTrackingGet, accessTokenProvider],
  );

  const importOffer = useCallback(
    async (
      id: string,
      buyerCompanyName: string,
      opportunityId?: string,
    ): Promise<AwsPrivateOffer> => {
      const json: {
        private_offer: {
          buyer_company_name: string;
          salesforce?: {
            opportunity_id: string;
          };
        };
      } = {
        private_offer: {
          buyer_company_name: buyerCompanyName,
        },
      };
      if (opportunityId) {
        json.private_offer.salesforce = { opportunity_id: opportunityId };
      }
      return stateTrackingSubmit(
        OfferAPIKey.Import,
        () =>
          offersApi(accessTokenProvider).post(
            `public/v2/private-offers/associate/${id}`,
            { json },
          ),
        convertPrivateOfferResponseToPrivateOffer,
        handleOfferImportError,
      );
    },
    [stateTrackingSubmit, handleOfferImportError, accessTokenProvider],
  );

  return {
    getOffer,
    getOfferSilently,
    getOfferForAgreement,
    getAgreementMappedAsPrivateOffer,
    getOffersForOpportunity,
    createOffer,
    updateOffer,
    cancelOffer,
    sendBuyerInstructions,
    archiveOffer,
    getVendorCurrencies,
    getAwsMarketplacePricing,
    getAgreements,
    importOffer,
  };
};

export default useAwsPrivateOfferApi;
