import { useAmpliFeatureFlags } from '@tackle-io/tackle-auth-tools';
import { FeatureFlagState } from 'packages/cosell/src/types/enums';
import { FEATURE_FLAGS_CO_SELL_V3_UI } from 'packages/cosell/src/utilities/constants';

function useIsV3OnlyCustomer(): boolean {
  const { flags: ampliFlags, error: errorLoadingFF } = useAmpliFeatureFlags();
  const isDevTestingV3OnboardingMigration =
    ampliFlags?.[
      FEATURE_FLAGS_CO_SELL_V3_UI.DEV_TESTING_V3_ONBOARDING_MIGRATION
    ] === FeatureFlagState.ON;

  return (
    ampliFlags?.[
      FEATURE_FLAGS_CO_SELL_V3_UI.CO_SELL_V2_VENDORS_NOT_MIGRATED
    ] === undefined &&
    !errorLoadingFF &&
    isDevTestingV3OnboardingMigration
  );
}

export default useIsV3OnlyCustomer;
