import { Button } from '@tackle-io/platform-ui';
import { Box } from 'vendor/material';
import { type CustomerAccountDetailsType } from '../customerAccountTransformSchema';
import { mockCustomerAccountsList } from '../fixtures';

/** for development only */
const MockCustomerList = ({
  onSelect,
}: {
  onSelect: (customerDetails: CustomerAccountDetailsType) => void;
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ gap: '2rem', marginTop: '2rem' }}
    >
      <div>Select a mock customer to fill form</div>
      <Box display="grid" gridGap={'1rem'}>
        {mockCustomerAccountsList?.map((account, index) => (
          <Button
            role="button"
            data-testid={`mockCustomerAccount-${index}`}
            key={account.customerAccountDetails.msp_accountnumber}
            onClick={() => onSelect(account.customerAccountDetails)}
          >
            <b style={{ marginRight: '1rem' }}>
              {account.customerAccountDetails.name}
            </b>
            {account.customerAccountDetails.msp_accountnumber}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default MockCustomerList;
