import type { ReferralFormFields } from '../referralFormSchema';

export const mockCustomerAccountsList = [
  {
    childAccounts: [],
    customerAccountDetails: {
      name: 'Customer is NOT MACC eligible',
      msp_accountnumber: '7-MVPHEKEGW',
      address1_line2: 'STORE #0',
      msp_mssalesid: 'e7e6954c-d1ae-46d2-ba8b-d4bf358643a4',
      address1_country: 'United States',
      address1_city: 'Redmond',
      msp_endcustomersegmentcode: 'Small, Medium & Corporate Commercial',
      msp_endcustomersubsegmentcode: 'SM&C Commercial - SMB',
      address1_line1: '1 Microsoft Way',
      territoryidname: 'SMB.USA - SeattleEastside-WA',
      matchscore: 23.316965,
      msp_customermacceligibility: 'No',
      msp_verticalcode: 'At scale SW, data & platforms',
      msp_subverticalcode: 'At scale SW, data & platforms',
    },
  },
  {
    childAccounts: [],
    customerAccountDetails: {
      name: 'Customer is MACC eligible',
      msp_accountnumber: '9-344KJTCVDN',
      msp_mssalesid: 'f791f9f9-2d8f-4a13-92d2-629a7a611861',
      address1_country: 'United States',
      address1_city: 'Miami',
      msp_endcustomersegmentcode: 'Small, Medium & Corporate Public Sector',
      msp_endcustomersubsegmentcode: 'SM&C Education - SMB',
      address1_line1: 'Test',
      territoryidname: 'United States.UNM.Unassigned',
      matchscore: 21.689758,
      msp_customermacceligibility: 'Yes', // actually No, but wanted to test yes
      msp_verticalcode: 'N/A',
      msp_subverticalcode: 'Unknown',
    },
  },
] as const;

export const MockCustomerProfileInitialValues: Record<
  string,
  ReferralFormFields['customerProfile']
> = {
  managedAccount: {
    name: 'Cool company',
    address: {
      addressLine1: '1234 Microsoft Way',
      city: 'Seattle',
      country: 'US',
    },
    ids: [{ id: '1234567890', profileType: 'External' }],
    isMatchingComplete: 'true',
    isMaccEligible: 'Yes',
    team: [
      {
        firstName: 'Nikola',
        lastName: 'Jokic',
        email: 'email@example.com',
        phoneNumber: '123-456-7890',
      },
    ],
  },
};
