import { type ComponentProps } from 'react';
import { Controller } from 'react-hook-form';
import { get } from 'lodash-es';
import TextInputField from '../components/TextInputField';
import { useReferralFormContext } from '../ReferralFormContext';

/** controlled by react-hook-form context */
const ControlledTextField = ({
  name,
  ...inputProps
}: ComponentProps<typeof TextInputField>) => {
  const { control, errors, formState } = useReferralFormContext();
  const isTouched = get(formState.touched, name);
  const errorMessage = isTouched && get(errors, name)?.message;

  return (
    <Controller
      control={control}
      name={name}
      render={({ value = '', onChange, onBlur }) => (
        <TextInputField
          {...inputProps}
          errorMessage={errorMessage}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );
};
export default ControlledTextField;
