import { FieldOption } from './types';

export const FIELD_MAPPERL_LABEL = 'field-mapper-label' as const;
export const CO_SELL_LABEL = 'Co-Sell' as const;
export const OFFERS_LABEL = 'Offers' as const;
export const FORM_PATH_DELINEATOR = '#' as const;
export const DOMAIN_PATH_DELINEATOR = '.' as const;
export const LIST_FIELD_OPTIONS_QUERY_LIMIT = 2000 as const;
export const DEFAULT_FIELD_OPTIONS_QUERY_LIMIT = 1000 as const;
export const SEARCH_DEBOUNCE_MS = 300 as const;
export const SEARCH_LOADING_ICON_WIDTH = 16 as const;
export const SEARCH_LOADING_ICON_MARGIN_RIGHT = '-30px' as const;
// setting to 3 initially
export const LIST_FIELD_PATHS_QUERY_DEPTH = 3 as const;

export enum Cloud {
  AWS = 'aws',
  GCP = 'gcp',
  MSFT = 'msft',
  AZURE = 'azure',
}

export enum Domain {
  CO_SELL = 'co-sell',
  OFFER = 'offer',
}

export enum Operation {
  CREATE_DIRECT_OFFER = 'createDirectOffer',
  CREATE_PARTNER_OFFER = 'createPartnerOffer',
  CREATE_OPPORTUNITY = 'createOpportunity',
}

export enum ColumnWidths {
  FIELD_NAME = 2,
  FIELD_TYPE = 1,
  SALESFORCE_FIELD = 5,
  PICKLIST_BUTTON = 1,
  DEFAULT_VALUE = 3,
}

export enum DefaultFieldType {
  NONE = 'none',
  FREEFORM_TEXT = 'freeform-text',
  SELECT = 'select',
  MULTI_SELECT = 'multi-select',
}

// NOTE: "microsoft" is used interchangeably in Downstream. For the purposes of Data Mapper we want it to be set to "msft"
// when interacting with the API
export const CLOUD_LOOKUP = {
  [Cloud.AWS]: Cloud.AWS,
  [Cloud.GCP]: Cloud.GCP,
  [Cloud.MSFT]: Cloud.MSFT,
  microsoft: Cloud.MSFT,
  [Cloud.AZURE]: Cloud.MSFT,
} as const;

export enum CloudDisplayValues {
  AWS = 'AWS',
  GCP = 'GCP',
  MICROSOFT = 'Microsoft',
  AZURE = 'Azure',
}

export const CLOUD_LABEL_LOOKUP = {
  [Cloud.AWS]: CloudDisplayValues.AWS,
  [Cloud.GCP]: CloudDisplayValues.GCP,
  [Cloud.MSFT]: CloudDisplayValues.MICROSOFT,
  [Cloud.AZURE]: CloudDisplayValues.MICROSOFT,
} as const;

export const CO_SELL_CLOUD_PORTAL_LABEL_MAP = {
  [Cloud.AWS]: 'AWS Partner Central',
  [Cloud.GCP]: 'Google Partner Hub',
  [Cloud.MSFT]: 'Microsoft Partner Center',
  [Cloud.AZURE]: 'Microsoft Partner Center',
} as const;

export const OFFER_CLOUD_PORTAL_LABEL_MAP = {
  [Cloud.AWS]: 'AWS Marketplace',
  [Cloud.GCP]: 'Google Cloud Marketplace',
  [Cloud.MSFT]: 'Microsoft Partner Center',
  [Cloud.AZURE]: 'Microsoft Partner Center',
} as const;

export const DOMAIN_LABEL_LOOKUP = {
  [Domain.CO_SELL]: 'Co-Sell',
  [Domain.OFFER]: 'Offer',
} as const;

export const OPERATION_LABEL_LOOKUP = {
  [Operation.CREATE_DIRECT_OFFER]: 'Direct offer',
  [Operation.CREATE_PARTNER_OFFER]: 'Partner offer',
  [Operation.CREATE_OPPORTUNITY]: 'Opportunity',
} as const;

export enum DomainDataType {
  STRING = 'string',
  NUMBER = 'number',
  INTEGER = 'integer',
  BOOLEAN = 'boolean',
  ARRAY = 'array',
  OBJECT = 'object',
  NULL = 'null',
}

export enum DomainFormFieldType {
  TEXT = 'text',
  PICKLIST = 'picklist',
  URL = 'url',
  STRING = 'string',
  ARRAY = 'array',
  DATE = 'date',
  LONG_TEXT = 'long_text',
  CURRENCY = 'currency',
  NONE = 'none',
}

export enum FieldOptionDataType {
  REFERENCE = 'reference',
  CHILD = 'child',
  BOOLEAN = 'boolean',
  SCALAR = 'scalar',
  STRING = 'string',
  PICKLIST = 'picklist',
  URL = 'url',
  PHONE = 'phone',
  TEXTAREA = 'textarea',
  PERCENT = 'percent',
  CURRENCY = 'currency',
  EMAIL = 'email',
  ENCRYPTEDSTRING = 'encryptedstring',
  ADDRESS = 'address',
  CALCULATED = 'calculated',
  COMBOBOX = 'combobox',
  MULTIPICKLIST = 'multipicklist',
  FLOAT_ARRAY = 'floatarray',
  ID = 'id',
  DATE = 'date',
  DATE_TIME = 'dateTime',
  DOUBLE = 'double',
  INT = 'int',
  LONG = 'long',
  TIME = 'time',
  BASE64 = 'base64',
  CUSTOM_TEXT = 'custom_text',
  LOCATION = 'location',
}

// NOTE: These types are in progress and will be refined
export const FIELD_TYPE_LABEL_LOOKUP = {
  [DomainFormFieldType.TEXT]: 'Text',
  [DomainFormFieldType.PICKLIST]: 'Picklist',
  [DomainFormFieldType.URL]: 'URL',
  [DomainFormFieldType.STRING]: 'String',
  [DomainFormFieldType.DATE]: 'Date',
  [DomainFormFieldType.LONG_TEXT]: 'Long text',
  [DomainFormFieldType.CURRENCY]: 'Currency',
} as const;

export enum StartingObjectId {
  OPPORTUNITY = 'Opportunity',
  QUOTE = 'Quote',
}

export const INVALID_FIELD_DOMAIN_PATH = 'invalid_field';

export const INVALID_FIELD = {
  domainPath: INVALID_FIELD_DOMAIN_PATH,
  domainDataType: DomainDataType.STRING,
  formMetadata: { label: 'Invalid field' },
  mappingField: {
    domainDataType: DomainDataType.STRING,
  },
} as const;

export const OPERATION_DEFAULT_VALUE_BY_CLOUD_MAP = {
  [Cloud.AWS]: {
    [Domain.CO_SELL]: Operation.CREATE_OPPORTUNITY,
    [Domain.OFFER]: Operation.CREATE_DIRECT_OFFER,
  },
  [Cloud.GCP]: {
    [Domain.CO_SELL]: Operation.CREATE_OPPORTUNITY,
    [Domain.OFFER]: Operation.CREATE_DIRECT_OFFER,
  },
  [Cloud.MSFT]: {
    [Domain.CO_SELL]: Operation.CREATE_OPPORTUNITY,
    [Domain.OFFER]: Operation.CREATE_DIRECT_OFFER,
  },
} as const;

/**
 * Salesforce Field Options (Objects)
 */

export const OPPORTUNITY_STARTING_OBJECT: FieldOption = {
  fieldId: 'Opportunity',
  fieldLabel: 'Opportunity',
  parentId: 'Opportunity',
  parentLabel: 'Opportunity',
  dataType: FieldOptionDataType.REFERENCE,
  fieldCharacterLimit: '12',
  referenceTo: 'Opportunity',
} as const;

export const QUOTE_STARTING_OBJECT: FieldOption = {
  fieldId: 'Quote',
  fieldLabel: 'Quote',
  parentId: 'Quote',
  parentLabel: 'Quote',
  dataType: FieldOptionDataType.REFERENCE,
  fieldCharacterLimit: '12',
  referenceTo: 'Quote',
} as const;

export const ACCOUNT_CONTACTS_CHILD_OBJECT = {
  dataType: FieldOptionDataType.CHILD,
  fieldId: 'Contacts',
  fieldLabel: 'Contacts',
  parentId: 'Account',
  parentLabel: 'Account',
  referenceTo: 'Contact',
  relationshipName: 'Contacts',
} as const;

// RBAC granular permissions
export const SETTINGS_LIST_MAPPINGS = 'settings:ListMappings' as const;
export const SETTINGS_GET_MAPPING = 'settings:GetMapping' as const;
export const SETTINGS_CREATE_MAPPING = 'settings:CreateMapping' as const;
export const SETTINGS_UPDATE_MAPPING = 'settings:UpdateMapping' as const;
export const SETTINGS_DELETE_MAPPING = 'settings:DeleteMapping' as const;
export const SETTINGS_GET_MAPPING_FIELD_OPTIONS_STATUS =
  'settings:GetMappingFieldOptionsStatus' as const;
export const SETTINGS_LIST_MAPPING_FIELD_OPTIONS =
  'settings:ListMappingFieldOptions' as const;
export const SETTINGS_SYNC_MAPPING_FIELD_OPTIONS =
  'settings:SyncMappingFieldOptions' as const;

export const FIELD_MAPPER_GRANULAR_RBAC_PERMISSIONS = [
  SETTINGS_LIST_MAPPINGS,
  SETTINGS_GET_MAPPING,
  SETTINGS_CREATE_MAPPING,
  SETTINGS_UPDATE_MAPPING,
  SETTINGS_DELETE_MAPPING,
  SETTINGS_GET_MAPPING_FIELD_OPTIONS_STATUS,
  SETTINGS_LIST_MAPPING_FIELD_OPTIONS,
  SETTINGS_SYNC_MAPPING_FIELD_OPTIONS,
] as const;

// list /field-options type param
export enum ListOptionsTypeParam {
  CHILD = 'child',
  REFERENCE = 'reference',
}

// Picklist enabled salesforce field types
export const PICKLIST_FIELD_SALESFORCE_FIELD_TYPES = [
  FieldOptionDataType.PICKLIST,
  FieldOptionDataType.MULTIPICKLIST,
];

export enum DomainPicklistAutoMapFields {
  CO_SELL_AWS_COUNTRY_CODE = 'customer.account.address.countryCode',
  CO_SELL_AWS_STATE_OR_REGION = 'customer.account.address.stateOrRegion',
}

export const CUSTOM_DOMAIN_PICKLIST_FIELDS = [
  DomainPicklistAutoMapFields.CO_SELL_AWS_COUNTRY_CODE,
  DomainPicklistAutoMapFields.CO_SELL_AWS_STATE_OR_REGION,
] as const;

// Picklist enabled salesforce field types
export const CUSTOM_DOMAIN_PICKLIST_FIELD_SALESFORCE_TYPES = [
  FieldOptionDataType.REFERENCE,
  FieldOptionDataType.PICKLIST,
  FieldOptionDataType.MULTIPICKLIST,
  FieldOptionDataType.STRING,
];

export enum FieldOptionSyncStatus {
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
}

// Salesforce field options that are compound data types
export const COMPOUND_DATA_TYPE_FIELD_OPTIONS = [
  FieldOptionDataType.ADDRESS,
  FieldOptionDataType.LOCATION,
];
