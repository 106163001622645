import { z } from 'zod';

/** the shape of the data returned from the customer account lookup */
export const CustomerAccountDetailsSchema = z.object({
  name: z.string(),
  address1_line2: z.string().optional(),
  address1_line1: z.string(),
  address1_city: z.string(),
  address1_country: z.string(),
  msp_accountnumber: z.string(),
  msp_customermacceligibility: z.enum(['Yes', 'No']).default('No'),
  msp_mssalesid: z.string(),
  msp_endcustomersegmentcode: z.string(),
  msp_endcustomersubsegmentcode: z.string(),
  matchscore: z.number(),
  territoryidname: z.string(),
  msp_verticalcode: z.string(),
  msp_subverticalcode: z.string(),
});

/** schema to transform customer lookup account data to
 *  the selected customer account data fields used in the form
 */
export const CustomerAccountToSelectedCustomerFieldsSchema =
  CustomerAccountDetailsSchema.transform((data) => ({
    name: data.name,
    address: {
      addressLine1: data.address1_line1,
      city: data.address1_city,
      country: data.address1_country,
      postalCode: '',
      region: '',
    },
    isMatchingComplete: 'true' as const,
    isMaccEligible: data.msp_customermacceligibility,
    ids: [
      {
        id: data.msp_accountnumber,
        profileType: 'External' as const,
      },
    ],
  }));

export type CustomerAccountDetailsType = z.input<
  typeof CustomerAccountToSelectedCustomerFieldsSchema
>;
