import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/aws/api/types/AwsPrivateOffer';
import {
  ActivitySlug,
  Marketplace,
  TackleOffer,
} from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';

export const awsPrivateOfferToTackleOffer = (
  offer: AwsPrivateOffer,
): TackleOffer => {
  const activities = offer.activities.map((a) => ({
    slug: a.slug as ActivitySlug,
    createdAt: a.createdAt,
    metadata: a.metadata,
    activityType: a.activityType,
    userId: a.userId,
  }));

  const buyers = offer.buyers.map((b) => ({
    emailAddress: b.emailAddress,
    fullName: b.fullName,
    title: b.title,
  }));

  const offerUsersToNotify = offer.extraData?.users || [];

  const usersToNotify = offerUsersToNotify.map((u) => ({
    email: u.email,
  }));

  return {
    poId: offer.poId,
    marketplace: Marketplace.Aws,
    offerType: offer.offerType,
    offerId: offer.offerId,
    sourceOfferId: offer.sourceOfferId,
    productId: offer.productId,
    billingAccountId: offer.buyerBillingAccountRef,
    buyerCompanyName: offer.buyerCompanyName,
    offerName: offer.offerName,
    activities,
    buyers,
    usersToNotify,
    offerMetadata: offer.offerMetadata,
    preRegistrationDetails: offer.preRegistrationDetails,
    acceptedAt: offer.acceptedAt,
    archivedAt: offer.archivedAt,
    cancelledAt: offer.cancelledAt,
    createdAt: offer.createdAt,
    createdInMarketplaceAt: offer.createdInMarketplaceAt,
    lastModifiedAt: offer.lastModifiedAt,
    openedInstructionsAt: offer.openedInstructionsAt,
    sentAt: offer.sentAt,
    viewedOfferAt: offer.viewedOfferAt,
    offerExpirationAt: offer.offerExpirationAt,
  };
};
