export enum CoSellMsftCreateFormDataId {
  QUICK_LINKS = 'cosell-msft-quicklinks',
  SECTION_PREFIX = 'cosell-msft-section',
  FIELD_PREFIX = 'cosell-msft-field',
  ACTION_PREFIX = 'cosell-msft-button',
}

export const FormSectionConfig = {
  customerProfile: {
    id: 'customerProfile',
    title: 'Customer details',
    dataId: `${CoSellMsftCreateFormDataId.SECTION_PREFIX}-customer-profile`,
  },
  dealDetails: {
    id: 'dealDetails',
    title: 'Deal details',
    dataId: `${CoSellMsftCreateFormDataId.SECTION_PREFIX}-deal-details`,
  },
  solutions: {
    id: 'solutions',
    title: 'Solutions',
    dataId: `${CoSellMsftCreateFormDataId.SECTION_PREFIX}-solutions`,
  },
  typeOfHelp: {
    id: 'typeOfHelp',
    title: 'Type of help',
    dataId: `${CoSellMsftCreateFormDataId.SECTION_PREFIX}-type-of-help`,
  },
  partners: {
    id: 'partners',
    title: 'Partners',
    dataId: `${CoSellMsftCreateFormDataId.SECTION_PREFIX}-partners`,
  },
  teamMembers: {
    id: 'teamMembers',
    subtitle:
      'Add up to five team members. They will receive referral notifications from Microsoft and Tackle. Tackle recommends adding an email alias to centralize notifications to your co-sell operations.',
    title: 'Team members',
    dataId: `${CoSellMsftCreateFormDataId.SECTION_PREFIX}-team-members`,
  },
} as const;

/** union of Form section ids */
export type FormSection = keyof typeof FormSectionConfig;
