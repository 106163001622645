import { z } from 'zod';
import { PhoneSchema } from '../utils/zod-custom-schemas';

export const selectedCustomerAccountHiddenFields = [
  'customerProfile.name',
  'customerProfile.address.addressLine1',
  'customerProfile.address.city',
  'customerProfile.address.country',
  'customerProfile.address.postalCode',
  'customerProfile.address.region',
  'customerProfile.isMatchingComplete',
  'customerProfile.isMaccEligible',
  'customerProfile.ids[0].id',
  'customerProfile.ids[0].profileType',
] as const;

export const customerTeamMemberFields = [
  {
    name: 'customerProfile.team[0].firstName',
    label: 'Customer first name',
    required: true,
    type: 'text',
  },
  {
    name: 'customerProfile.team[0].lastName',
    label: 'Customer last name',
    required: true,
    type: 'text',
  },
  {
    name: 'customerProfile.team[0].email',
    label: 'Customer email',
    required: true,
    type: 'email',
  },
  {
    name: 'customerProfile.team[0].phoneNumber',
    label: 'Customer phone',
    required: true,
    type: 'text',
  },
] as const;

/** zod schemas */

const CustomerProfileAddressSchema = z.object({
  addressLine1: z.string(),
  city: z.string(),
  /** The country/region in ISO 3166 2-letter country code format.  https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 Should be address1_country from customerAccountDetails converted to country code, address.country from duns. */
  country: z.string(),
  /** Not used if from customerAccountDetails, address.postalCode from duns. */
  postalCode: z.string().nullish().optional(),
  /** Not used if from customerAccountDetails, address.region from duns. */
  region: z.string().nullish().optional(),
});

const CustomerProfileIdsSchema = z.object({
  id: z.string(),
  profileType: z.enum(['External', 'duns']),
});

export const SelectedCustomerAccountSchema = z.object({
  name: z.string(),
  address: CustomerProfileAddressSchema,
  isMatchingComplete: z.enum(['true', 'false']).default('true'),
  isMaccEligible: z.enum(['Yes', 'No']).default('No'),
  ids: z.array(CustomerProfileIdsSchema).min(1, 'Required'),
});

export type SelectedCustomerAccountType = z.infer<
  typeof SelectedCustomerAccountSchema
>;

const CustomerProfileTeamMemberSchema = z.object({
  firstName: z.string().min(1, 'Required'),
  lastName: z.string().min(1, 'Required'),
  phoneNumber: PhoneSchema,
  email: z.string().min(1, 'Required').email(),
});

export const CustomerProfileSchema = SelectedCustomerAccountSchema.merge(
  z.object({
    team: z.array(CustomerProfileTeamMemberSchema).min(1, 'Required'),
  }),
);

export type CustomerProfileFields = z.infer<typeof CustomerProfileSchema>;
