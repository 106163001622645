import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import OffersCallToAction from './OffersCallToAction';
import { useCanvasSession } from '../src/useCanvasSession';
import { Box } from 'vendor/material';
import { Loader } from '@tackle-io/platform-ui';
import ListOfOffersForOpportunity from './ListOfOffersForOpportunity';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/aws/api/types/AwsPrivateOffer';
import { useRbac } from 'utils/rbac';
import NotEnoughPermissionsTabContent from '../src/components/NotEnoughPermissionsTabContent';
import { ApiContextProvider } from 'pages/PrivateOffers/pages/Next/generic/ApiContext/ApiContextProvider';

export const OffersTabContentContext = createContext({
  fetchOffersForOpportunity: () => {},
});

const OffersTabContent = () => {
  const {
    awsApi: { getOffersForOpportunity },
  } = useContext(ApiContext);

  const session = useCanvasSession();
  const [offers, setOffers] = useState<AwsPrivateOffer[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchOffersForOpportunity = useCallback(async () => {
    if (!session.record?.Id) return;
    setLoading(true);
    try {
      const offersForOpportunity = await getOffersForOpportunity(
        session.record.Id,
      );
      setOffers(offersForOpportunity);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [getOffersForOpportunity, session.record?.Id]);

  useEffect(() => {
    fetchOffersForOpportunity();
  }, [fetchOffersForOpportunity]);

  return (
    <OffersTabContentContext.Provider
      value={{
        fetchOffersForOpportunity,
      }}
    >
      <Box overflow="auto" height="100%" position="relative">
        {loading && <Loader />}
        {error && <div>Error: {error.message}</div>}
        {!loading && (!offers || offers?.length === 0) && (
          <OffersCallToAction />
        )}
        {offers?.length > 0 && (
          <ListOfOffersForOpportunity
            opportunityId={session?.record?.Id}
            offers={offers}
          />
        )}
      </Box>
    </OffersTabContentContext.Provider>
  );
};

const OffersTabContentWithProvider = () => {
  const { hasPermission, loading } = useRbac();
  if (loading) {
    return <Loader />;
  }

  if (!hasPermission('offers:ListOffers')) {
    return (
      <NotEnoughPermissionsTabContent blurb="Your permissions are not configured to view or create offers. Contact your Salesforce admin to request changes." />
    );
  }
  return (
    <ApiContextProvider>
      <OffersTabContent />
    </ApiContextProvider>
  );
};

export default OffersTabContentWithProvider;
