import { type ComponentProps } from 'react';
import { TextField as TackleTextField } from '@tackle-io/platform-ui';
import { makeStyles } from 'vendor/material';
import { Box, Tooltip } from 'vendor/material';
import { InformationOutline } from 'mdi-material-ui';

const useStyles = makeStyles((theme) => ({
  formLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    marginLeft: theme.spacing(0.5),
  },
}));

interface LabelProps {
  className?: string;
  label: string;
  required?: boolean;
}

const Label = ({
  className,
  label,
  required = false,
}: LabelProps): JSX.Element => {
  return (
    <label className={className}>
      {label}
      {required ? <span style={{ color: 'red' }}>&nbsp;*</span> : ''}
    </label>
  );
};

const InputLabel = ({
  dataId,
  tooltipText,
  label,
  required,
}: ComponentProps<typeof Label> & { tooltipText?: string; dataId: string }) => {
  const classes = useStyles();
  if (!tooltipText) {
    return <Label label={label} required={required} />;
  }

  return (
    <Box className={classes.formLabel}>
      <Label label={label} required={required} />
      <Tooltip
        id={dataId}
        className={classes.tooltip}
        title={tooltipText}
        placement="top-start"
      >
        <InformationOutline fontSize="small" />
      </Tooltip>
    </Box>
  );
};

interface TextFieldProps extends ComponentProps<typeof TackleTextField> {
  label: string;
  tooltipText?: string;
  helperText?: string;
  dataId?: string;
}

/** A Tackle style text input field that has:
 *  - a label with or without a tooltip
 *  - helper text
 *  - error message display if provided
 */
const TextInputField = ({
  dataId,
  errorMessage,
  helperText,
  value,
  onChange,
  onBlur,
  name,
  tooltipText,
  required,
  label,
  ...inputProps
}: TextFieldProps) => {
  return (
    <TackleTextField
      data-id={dataId}
      id={name}
      name={name}
      label={
        <InputLabel
          dataId={dataId}
          tooltipText={tooltipText}
          label={label}
          required={required}
        />
      }
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target?.value ?? '';
        onChange(value);
      }}
      onBlur={onBlur}
      helperText={helperText}
      value={value}
      error={errorMessage}
      inputProps={inputProps}
      noValidate
    />
  );
};

export default TextInputField;
