import { decorate, observable, action, computed } from 'mobx';
import { DateTime, Duration } from 'luxon';
import {
  assign,
  isPlainObject,
  keys,
  get,
  camelCase,
  omit,
  map,
} from 'lodash-es';
import { flow, pick, mapValues, mapKeys } from 'lodash/fp';
import { toDateTime, infinity } from 'utils/dates';
import { awsMoneyToDinero } from 'utils/money';
import { convertKeysTo } from 'stores/utils';

import Entitlement from './v4Entitlement';
import Event from './v4Event';
import ActivityItem from './ActivityItem';
import { Cloud } from 'stores/typings';
import { PrivateOfferPricing } from 'stores/privateOffers/typings';

const mapValuesWithKey = (mapValues as any).convert({ cap: false });

class Contract {
  cloud: Cloud;
  cloudBuyerMetadata = {};
  cloudMetadata: Record<string, any> = {};
  disbursed = 0;
  entitlements = [];
  estimatedSubtotal = 0;
  events = [];
  expirationDate = '';
  fee = 0;
  id = '';
  listing = '';
  pricing: PrivateOfferPricing;
  privateOffer = false;
  privateOfferMetadata = {};
  registrationMetadata: Record<string, any> = {};
  contractMetadata = {};
  startDate = '';
  subtotal = 0;
  tax = 0;
  activity = [];
  usersToNotify = [];
  archivedAt: string | DateTime = '';
  poaArchivedAt = '';
  buyers = [];
  buyerName = '';

  constructor(props) {
    const null_dates = ['archived_at'];

    const infinite_dates = ['start_date', 'expiration_date'];

    const money = [
      'disbursed',
      'estimated_subtotal',
      'fee',
      'outstanding',
      'subtotal',
      'tax',
    ];

    const metadata = [
      'cloud_buyer_metadata',
      'cloud_metadata',
      'registration_metadata',
    ];

    const objs = {
      entitlements: Entitlement,
      events: Event,
      activity: ActivityItem,
      users_to_notify: null,
      buyers: null,
    };

    // assign infinite dates
    assign(
      this,
      flow(
        pick(infinite_dates),
        mapValues((v) => toDateTime(v ?? infinity)),
        /** @ts-expect-error -- Argument of type 'number' is not assignable to parameter of type 'string'. */
        mapKeys((k) => camelCase(k)),
      )(props),
    );

    // assign dates
    assign(
      this,
      flow(
        pick(null_dates),
        mapValues((v) => (v ? toDateTime(v) : null)),
        /** @ts-expect-error -- Argument of type 'number' is not assignable to parameter of type 'string'. */
        mapKeys((k) => camelCase(k)),
      )(props),
    );

    // assign money
    assign(
      this,
      flow(
        pick(money),
        mapValues((v) =>
          awsMoneyToDinero({
            amount: v,
            currency: props?.pricing?.currency_code || 'USD',
          }),
        ),
        /** @ts-expect-error - Argument of type 'number' is not assignable to parameter of type 'string'. */
        mapKeys((k) => camelCase(k)),
      )(props),
    );

    // assign metadata
    assign(
      this,
      flow(
        pick(metadata),
        mapValues((v) => (isPlainObject(v) ? v : {})),
        /** @ts-expect-error -- Argument of type 'number' is not assignable to parameter of type 'string'. */
        mapKeys((k) => camelCase(k)),
      )(props),
    );

    // assign object
    assign(
      this,
      flow(
        pick(keys(objs)),
        mapValuesWithKey((v, k) => {
          if (!isPlainObject(v)) {
            return [];
          }

          const dataResolver = (d) => (objs[k] ? new objs[k](d) : d);
          return map(get(v, 'data', []), dataResolver);
        }),
        /** @ts-expect-error -- Argument of type 'number' is not assignable to parameter of type 'string'. */
        mapKeys((k) => camelCase(k)),
      )(props),
    );

    this.pricing = convertKeysTo(camelCase, [])(props.pricing);
    const etc = ['buyers'];

    // assign item lists
    assign(
      this,
      flow(
        pick(etc),
        mapValuesWithKey((v) => {
          if (!isPlainObject(v)) return [];
          return map(get(v, 'data', []), convertKeysTo(camelCase));
        }),
      )(props),
    );

    // assign basic props
    const basicProps = omit(
      props,
      null_dates,
      infinite_dates,
      money,
      metadata,
      keys(objs),
      etc,
    );
    /** @ts-expect-error -- Argument of type 'Omit<any, string>' is not assignable to parameter of type 'List<any>' */
    assign(this, mapKeys((k) => camelCase(k))(basicProps));
  }

  setBuyerDetails = (buyers = []) => {
    this.buyers = buyers;
  };

  setPrivateOfferMetadata = (metadata = {}) => {
    this.privateOfferMetadata = metadata;
  };

  setRegistrationMetadata = (metadata = {}) => {
    this.registrationMetadata = metadata;
  };

  setContractMetadata = (metadata = {}) => {
    this.contractMetadata = metadata;
  };

  setUsersToNotify = (usersToNotify = []) => {
    this.usersToNotify = usersToNotify;
  };

  setActivity = (activity = []) => {
    this.activity = map(activity, (a) => new ActivityItem(a));
  };

  setArchivedAt = (archivedAt = null) => {
    this.archivedAt = toDateTime(archivedAt);
  };

  setPricing = (pricing: PrivateOfferPricing) => {
    this.pricing = pricing;
  };

  get isActive() {
    return {
      aws: toDateTime(this.expirationDate) > DateTime.utc(),
      azure: true,
      gcp: true,
      redhat: true,
    }[this.cloud];
  }

  get expiringSoon() {
    // This logic may be able to be refactored by using DateTime.Interval

    const expirationDate = toDateTime(this.expirationDate);
    return (
      expirationDate.isValid && // Invalid dates possible for never ending contracts
      expirationDate.valueOf() > DateTime.fromObject({}).valueOf() && // not in the past
      expirationDate.diffNow().valueOf() <
        Duration.fromObject({ months: 2 }).valueOf()
    ); // expires in < 2 months
  }

  get expired() {
    // This logic may be able to be refactored by using DateTime.Interval

    const expirationDate = toDateTime(this.expirationDate);
    return (
      expirationDate.isValid && // Invalid dates possible for never ending contracts
      expirationDate.valueOf() < DateTime.fromObject({}).valueOf()
    ); // not in the past
  }

  get meterable() {
    return this.isActive && !this.archivedAt;
  }

  // NOTE: This will cause a bug because we cannot garuntee that all listings
  // will have a Company field in the registrationMetadata. This is especially likely
  // for listings with custom registration pages.
  //
  // In the future we should allow the vendor to select which
  // registrationMetadata field to use as the primary field in the
  // displayName
  //
  // We should make this configurable per-listing for the vendor.

  get displayName() {
    return (
      this.registrationMetadata.Company ||
      this.registrationMetadata?.['Company Name'] ||
      this.buyerName ||
      'Unregistered'
    );
  }
}

decorate(Contract, {
  id: observable,
  entitlements: observable,
  events: observable,
  usersToNotify: observable,
  activity: observable,
  archivedAt: observable,
  cloudMetadata: observable,
  pricing: observable,
  isActive: computed,
  expiringSoon: computed,
  expired: computed,
  meterable: computed,
  setBuyerDetails: action,
  setRegistrationMetadata: action,
  setPrivateOfferMetadata: action,
  setContractMetadata: action,
  setUsersToNotify: action,
  setActivity: action,
  setArchivedAt: action,
  setPricing: action,
});

export default Contract;
